*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.layout{
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(2, 1fr);
   width: 100%;
 height: auto;
 margin: 150px 0px 200px 0px;
 gap: 0px;
 justify-content: center;
 padding: 10px;
 place-items: center;
 row-gap: 100px;

}

.card{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    width: 350px;
    height: 390px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 10px #5442A4 ;
    transition: 3s ease;
}

.card:hover{
    scale: 1.1;
    transition: 0s ease;
}

.profilepic2023{
    height: 100%;
    width: 100%;

    margin: 0;
}
.image2023{
    height: 100%;
    width: 100%;
     margin: 0;
  
}
.nameplate2023{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20%;
    background-color: rgba(0, 0, 0, 0.64);
    border-radius: 0px 0px 20px 20px ;
    justify-content: center;
    align-items: center;

    font-family: Poppins;
}

.name2023{
    color: white;
    font-size: 1.5rem;
    font-family: Poppins;
    font-weight: 700;
}

.data2023{
    color: #C64DFF;
    font-size: 0.8rem;
    font-family: Poppins;
}

.card2{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    width: 350px;
    height: 350px;
    border-radius: 30px;
    border-radius: 1000px;
    transition: 3s ease;
    overflow: hidden;
scale: 1.1;
}

.card2:hover{
    scale: 1.2;
    
    transition: 0s ease;
}

.profilepic2022{
    height: 100%;
    width: 100%;

    margin: 0;
    background-color: #DBA120;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
}
.image2022{
    height: 90%;
    width: 90%;
     margin: 0;
     border-radius: 1000px;
  
}
.nameplate2022{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height:30%;
    background-color: rgba(0, 0, 0, 0.64);
    border-radius: 0px 0px 20px 20px ;
    justify-content: flex-start;
    align-items: center;

    font-family: Poppins;
}

.name2022{
    color: white;
    font-size: 1.5rem;
    font-family: Poppins;
    font-weight: 700;
    margin-top: 10px;
}

.data2022{
    color: #DBA120;
    font-size: 0.8rem;
    font-family: Poppins;
}



  
  
  /* FOR LAPTOP, DESKTOP */
  
  @media only screen and (max-width: 1195px) {
    .layout{
        
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
  
     
     }
     
  }
  
  
  
  @media only screen and (max-width:780px) {

        .layout{
        
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(6, 1fr);
      
         
         }
     
  }

