.red-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s ease-in-out;
  }
  
  
  .SponsorCard.hovered .red-line {
    transform: scaleX(1);
  }
  body{
    background-color: #310e68;
    background: linear-gradient(128deg, #000000 0%, #06063d 50%, #781aaf 100%);

    height:100%
  }
  .grads{
    /* background-color: #310e68;
background-image: linear-gradient(145deg, #310e68 0%, #5f0f40 67%,
#0B7A82 ); */
/* background: linear-gradient(128deg,#210C4E,#40044A,#310C4E,#210C4E,#40044A,#290C4E); */
    height:100%
  }

  
/* @keyframes gradient {
  0% {
  background-position: 0% 50%;
  }
  50% {
  background-position: 100% 50%;
  }
  100% {
  background-position: 0% 50%;
  }
  }; */
  /* .theme_img { */
    /* position: absolute; */
    /* left: 10%;
    width: 12%; */
    /* opacity: 0.5; */
    /* height: 60%;
    width:60%;
  } */
  /* .hello{
    background-image:url('../images/sponsors/bg7.jpg');
    background-attachment: fixed;
    background-size:cover;
  } */
  .box
{
    height: 272px;
    width: 315px;
    /* border: 2px solid black; */
    border-radius: 30px;
    margin: 20px;
    /* background: linear-gradient(to bottom, #242222 15%,#ffffff); */
    background-color: rgb(255, 255, 255);
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* opacity: 0.5; */
    box-shadow: 10px 10px 10px 2px rgb(0, 0, 0);
    /* margin-right: 40px; */
    
}
.box:hover
{
   filter: brightness(80%);
   transform: scale(1.02);
   transition-property: all;
   transition-duration: 0.1s;
}
.logo
{
    width: 100%;
    height: 20%;
    /* border: 2px solid white; */
    /* margin-bottom: 5px; */
    
}
.image1
{
    width: 60%;
    height: 100%;
    /* mix-blend-mode:multiply; */
    padding-top: 10px;
    padding-left: 10px;
    /* opacity: 0.5; */
}
.centre
{
    width: 100%;
    height: 75%;
    /* padding-top: 0px; */
    /* border: 1px solid #008000; */
    display: flex;
    
    justify-content: center;
    align-items:center;
}
.image2
{
    /* height: 100%; */
    /* width: 100%; */
    mix-blend-mode: multiply;
    /* scale: 0.5; */
    /* border: 2px solid blue; */
    object-fit: cover;
    transform: scale(0.8);
    
    
}
.encloser
{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.description
{
  /* text-align: center; */
  /* width: 100%; */
  /* background-color: chartreuse; */
  font-family: Poppins;
  
letter-spacing: 0.3rem;
transition: .4s ease;
  
  font-size: 25px;
 
  /* background: radial-gradient(red,black); */
  /* border-radius: 20px; */
  /* padding: 5px; */
}

.description:hover{
  letter-spacing: 0.8rem;
  transform: translateX(7px);
  filter: brightness(140%);
}

.title1 {
  background: -webkit-linear-gradient(45deg, rgba(195,146,52) ,rgb(169, 160, 97), rgba(195,146,52));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3rem;
  transition: .4s ease;
  
}
.title1:hover{
  
  letter-spacing: 1rem;
  transform: translateX(7px);
  filter: brightness(140%);
  }
