.events {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
  padding-bottom: 0%;
  visibility: visible;
}

.eventsheading {
  
  padding-top: 75px;
  padding-top: 90px;
  background: -webkit-linear-gradient(45deg, rgba(195,146,52) ,rgb(169, 160, 97), rgba(195,146,52));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  
  visibility: visible;
  margin-bottom: 15px;
  letter-spacing: 0.3rem;
  transition: .4s ease;
}
.eventsheading:hover{
  letter-spacing: 0.8rem;
  transform: translateX(7px);
  filter: brightness(140%);
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  width: 25vw;
  height: 25vw;
  min-width: 200px;
  min-height: 200px;
  margin: 35px;
  visibility: visible;
  transition: transform 0.3s;
  margin-bottom: 40px;
}

.cardframe, .cardpicture, .cardpicture img, .cardcontent, .cardoverlay {
  width: 100%;
  height: 100%;
}

.cardframe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  
}

.cardpicture {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardpicture img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.cardoverlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  cursor: pointer;
  z-index: 2;
}

.cardoverlay::before {
  content: "Know More";
  padding-left: 0.8rem;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: #000;
  transition: color 0.5s ease;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.card:hover .cardoverlay::before {
  color: #fff;
  text-shadow: none;
}

.card:hover .cardoverlay {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 30px;
  opacity: 1;
}

.cardcontent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
  padding: 20px;
  line-height: 1.4;
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  pointer-events: none;
  transition: opacity 0.8s ease, visibility 0.8s ease;
}

.card:hover .cardcontent {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.cardcontent .cardvideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2); 
}

.cardcontent .cardtext {
  position: relative;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 20px;
}

.cardcontent .descriptiontitle,
.cardcontent .descriptiontext {
  color: white;
}

.cardcontent .registerbutton {
  margin-top: 20px;
  
  padding: 8px 16px; /* Adjusted padding for smaller size */
  background-color: #781aaf;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.cardcontent .registerbutton:hover {
  background-color: #5c137b;
}

.card:hover {
  transform: translateY(-10px);
}

@media (max-width: 1200px) {
  .card {
      width: 30vw;
      height: 30vw;
  }
}

@media (max-width: 900px) {
  .card {
      width: 35vw;
      height: 35vw;
  }
}

@media (max-width: 600px) {
  .card {
      width: 60vw;
      height: 60vw;
  }
}

@media (max-width: 400px) {
  .eventsheading {
      font-size: 3.5rem;
  }
  .card {
      width: 60vw;
      height: 60vw;
  }
}
.cardcontent .descriptiontext {
  color: white;
  font-size: 1rem; /* Default font size */
}
@media (max-width: 1400px) {
  .cardcontent .descriptiontext {
      font-size: 0.8rem;
  }
}

@media (max-width: 1200px) {
  .cardcontent .descriptiontext {
      font-size: 0.9rem;
  }
}

@media (max-width: 900px) {
  .cardcontent .descriptiontext {
      font-size: 0.6rem;
  }
}

@media (max-width: 600px) {
  .cardcontent .descriptiontext {
      font-size: 0.9rem;
  }
}

@media (max-width: 420px) {
  .cardcontent .descriptiontext {
      font-size: 0.6rem;
  }}

  @media (max-width: 630px) {
    .cardcontent .registerbutton {
        padding: 6px 12px; 
         
    }
    
}

@media (max-width: 630px) {
  .eventsheading {
      font-size: 3rem; 
  }
}
