@import url('https://fonts.googleapis.com/css2?family=Silkscreen:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lexend+Peta:wght@100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  position: relative;
}



.grid {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  grid-template-rows: repeat(90, 1fr);
  width: 100%;
  height: 100%;
  z-index: 0;
  /* background-color: ; */
  gap: 0.3vw;
  overflow: hidden;
}

.gridblock {
  background-color: transparent;
  transition: .3s ease;
  border-radius: 100px;
}

.gridblock:hover {
  /* background-color: rgba(163,40,24); */
  transition: none;
  box-shadow: 0px 0px 10px white;
}

.bganim {
  position: absolute;
  width: 100%;
  height: 10vh;
  background-color: rgba(163, 40, 24);
  z-index: -1;
  filter: blur(15vh);



  animation-name: bganim;
  animation-duration: 10s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: normal;

}

@keyframes bganim {
  0% {
    top: -25vh;
  }

  100% {
    top: 110vh;
  }
}

.content {
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.heading2 {

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
  flex-wrap: wrap;

}




.containercurrent {
  width: 100%;
  height: 20vh;
  background-color: black;
  background: url(../images/frames/bg.png);
  background-position: center 70%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-shadow: inset 0px 0px 100px 50px black;
}



.container2023 {
  width: 100%;
  height: auto;
  position: relative;
  /* height: 100vh; */
  background: linear-gradient(135deg, #A128D9, #020817, #152A5B);
  display: flex;
  justify-content: center;
}

.container2022 {
  width: 100%;
  height: auto;
  position: relative;

  background: #070E21;
  display: flex;
  justify-content: center;
}

.finisher {
  width: 100%;
  /* height: auto; */
  position: relative;
  height: auto;
  background: transparent;
  display: flex;
  justify-content: center;

}

.finisher .strap {
  transform: translateY(-100%);
}

.strap {
  font-family: Lexend Peta;
  font-weight: 900;
  color: white;
  position: absolute;
  top: 0;
  transform: translateY(-55%);
  letter-spacing: -6px;
  word-spacing: 3px;
  font-size: 1.5rem;
  width: 140vw;
  overflow: hidden;
  height: 2rem;
  /* display: flex;
  justify-content: center; */

  animation-name: transit;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;

}


@keyframes transit {

  /* 0%{
    top: 0;
  } */
  0% {
    top: -5px;
  }

  /* 50%{
    top: 0;

  } */
  100% {
    top: 5px;
  }

  /* 100%{
    top: 0;
  } */

}

.year {
  font-family: Lexend Peta;
  font-weight: 900;
  color: white;
  position: absolute;
  top: 0;
  transform: translateY(-55%);

  display: flex;
  justify-content: center;

  font-size: 13rem;
  letter-spacing: -40px;
  z-index: 10;

  transition: .1s ease;
}

.year:hover {
  letter-spacing: 20px;
  scale: 1.2;
  transition: 3s ease;
}

.banner1 {
  position: absolute;
  top: 0;
  left: 0;
}

.banner2 {
  position: absolute;
  top: 120vh;
  right: 0;

  transform: rotateZ(180deg);

}


.triangle1,
.triangle2,
.triangle3 {
  background: url(../images/frames/triangle.png);
  height: 200px;
  width: 200px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 25%;
}


.triangle2 {

  height: 300px;
  width: 300px;




}

.triangle3 {

  height: 400px;
  width: 400px;




}



.banner3{
  transform: rotateZ(-90deg);
}













/* FOR MOBILE, TABLET */
@media only screen and (max-width: 550px) {

  .year {
    font-size: 9rem;
    letter-spacing: -32px;
  }
}


/* FOR LAPTOP, DESKTOP */

@media only screen and (max-width: 992px) {
  .year {
    font-size: 9rem;
    letter-spacing: -36px;
  }
}



@media only screen and (max-width:400px) {
  .year {
    font-size: 7rem;
    letter-spacing: -28px;
  }
}






/* FOR LAPTOP, DESKTOP */

@media only screen and (max-width: 1195px) {
  

   .banner2 {
    position: absolute;
    top: 187vh;
    right: 0;
  
    transform: rotateZ(180deg);
  
  }
   
}



@media only screen and (max-width:780px) {

      

       .banner2 {
        position: absolute;
        top: 345vh;
        right: 0;
      
        transform: rotateZ(180deg);
      
      }
   
}

