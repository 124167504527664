html,
body {
  overflow-x: hidden;
}

.navbar,
.homenavbar {
  position: fixed;
  z-index: 100;
  font-weight: 460;
  transition: background-color 1s ease-in;
}

.homenavbar {
  width: 100vw;
  display: grid;
  padding-top: 1%;
  background-color: transparent;
  grid-template-columns: 15% 80% 5%;
  animation: fadeInBackground 10s forwards;
}

@keyframes fadeInBackground {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.homenavbar.scrolled {
  background-color: rgba(0, 0, 0, 0.5);
}

.stickyblow {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
/* .temp_nav {
  height: 60px;
} */
.nav_img_home img {
  display: inline-block;
  height: 65px;
  object-fit: contain;
  
  padding-bottom: 20px;
  
  padding-left: 20px;
}
.nav_img img {
  display: inline-block;
  height: 65px;
  object-fit: contain;
  
  padding-bottom: 20px;
  
  padding-left: 20px;
}

/* .nav_img img {
  display: inline-block;
  height: 45px;
  object-fit: scale-down;
  padding: 5px;
  padding-left: 20px;
} */

.navbar {
  /* z-index: 1000000000; */
  width: 100vw;
  display: grid;
  grid-template-columns: 15% 80% 5%;
}

.nav_list {
  display: flex;
  padding-top: 12px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style: none;
  font-size: 18px;
  text-decoration: none;
}

.nav_list1 {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  font-size: 18px;
  text-decoration: none;
}

.navbar a:not([href]):not([tabindex]),
.homenavbar a:not([href]):not([tabindex]) {
  display: inline;
  text-decoration: none;
  color: white;
}

.navbar a:not([href]):not([tabindex]),
.homenavbar a:not([href]):not([tabindex]) {
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0)
    3px no-repeat;
  transition: 0.5s, background-position 0s 0.3s;
}

.navbar a:not([href]):not([tabindex]):hover,
.homenavbar a:not([href]):not([tabindex]):hover {
  text-decoration: none;
  /* color: #fcd365; */
  --d: 100%;
  --p: 100%;
}

.navbar .nav_item,
.homenavbar .nav_item {
  display: inline;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.navbar .nav_item,
.homenavbar .nav_item {
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0)
    3px no-repeat;
    color: white;
  transition: 0.5s, background-position 0s 0.3s;
}

.navbar .nav_item:hover,
.homenavbar .nav_item:hover {
  text-decoration: none;
  color: #fcd365;
  --d: 100%;
  --p: 100%;
}

@media (max-width: 768px) {
  .nav_item {
    text-decoration: none;
    /* color: #fcd365 !important; */
    --d: 100%;
    --p: 100%;
  }
}

.registration {
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style: none;
  font-size: 20px;
}

.log_in {
  display: inline;
  text-decoration: none;
  text-shadow: 0 5px rgba(239, 239, 239, 0.25);
  color: rgb(255, 255, 255);
  background-color: #000000;
  padding: 5px 30px;
  margin: 0;
  border: solid 5px;
  border-radius: 30px;
  border-color: #550cdf;
  transition: 300ms ease-out;
}

.log_in:hover {
  background-color: #550cdf;
  text-shadow: 0 5px rgba(255, 255, 255, 0.2);
}

.register {
  display: inline;
  text-shadow: 0 5px rgba(255, 255, 255, 0.2);
  text-decoration: none;
  margin: 0;
  color: white;
  background-color: #4e00df;
  padding: 10px 30px;
  border-radius: 20px;
  border-color: #550cdf;
  transition: 700ms ease-out;
}

.register:hover {
  color: #550cdf;
  background-color: white;
  text-shadow: 0 5px rgba(0, 0, 0, 0.174);
}

.resp_nav {
  display: none;
  color: white;
}

.hamburger {
  position: absolute;
  top: 5em;
  right: 3%;
  margin-left: -2em;
  margin-top: -60px;
  width: 2em;
  height: 45px;
  z-index: 5;
}

@media (max-width: 1000px) {
  .resp_nav {
    display: block;
  }

  .navbar,
  .homenavbar {
    display: grid;
    grid-template-columns: 20% 30% 50%;
  }

  .registration {
    grid-column-start: 3;
  }

  .list {
    display: none;
  }

  .nav_list {
    padding: 0;
    flex-direction: column;
    grid-row-start: 2;
    width: 100vw;
    height: 95vh;
    background-color: black;
  }

  .nav_list1 {
    padding-top: 5px;
    flex-direction: column;
    grid-row-start: 2;
    width: 100vw;
    height: 20vh;
    justify-content: space-evenly;
    /* align-items: stretch; */
    background-color: black;
  }

  .li_item {
    text-align: center;
  }

  .hamburger {
    margin-top: -90px;
  }
}

/* Navbar.css */
.nav_item {
  text-decoration: none; /* Ensure no underline by default */
  padding-top: 10px;
}

.nav_item:hover {
  text-decoration: none; /* Remove underline on hover */
}

@media (max-width: 600px) {
  /* .nav_img_home img {
    display: inline-block;
    height: 65px;
    object-fit: contain;
    
   
    
    padding-left: 20px;
  }
  .nav_img img {
    display: inline-block;
    height: 65px;
    object-fit: contain;
    

    padding-left: 20px;
  } */
  .navbar a:not([href]):not([tabindex]),
.homenavbar a:not([href]):not([tabindex]) {
  display: inline;
  text-decoration: none;
  color: white;
}

.navbar a:not([href]):not([tabindex]),
.homenavbar a:not([href]):not([tabindex]) {
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0)
    3px no-repeat;
  transition: 0.5s, background-position 0s 0.3s;
}

.navbar a:not([href]):not([tabindex]):hover,
.homenavbar a:not([href]):not([tabindex]):hover {
  text-decoration: none;
  /* color: #fcd365; */
  --d: 100%;
  --p: 100%;
}

.navbar .nav_item,
.homenavbar .nav_item {
  display: inline;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.navbar .nav_item,
.homenavbar .nav_item {
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0)
    3px no-repeat;
    color: white;
  transition: 0.5s, background-position 0s 0.3s;
}

.navbar .nav_item:hover,
.homenavbar .nav_item:hover {
  text-decoration: none;
  color: #fcd365;
  --d: 100%;
  --p: 100%;
}
  .navbar,
  .homenavbar {
    display: grid;
    grid-template-columns: 40% 5% 55%;
  }

  .registration {
    font-size: 15px;
  }

  .log_in {
    font-size: 15px;
    padding: 3px 10px;
  }

  .register {
    padding: 8px 10px;
  }

  .hamburger {
    margin-top: -60px;
  }
}

.hamburger div {
  position: relative;
  width: 2.1em;
  height: 4px;
  border-radius: 3px;
  background-color: #ffffff;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}

#toggle {
  display: none;
}

#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}

#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}

#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -4px;
}

#toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}

@media (max-width: 350px) {
  .nav_img img {
    height: 60px;
    padding-bottom:0;
    padding-left: 15px;
    padding-right: 0;
  }
  .nav_img_home img {
    height: 60px;
   padding-bottom:0;
   
    padding-left: 15px;
    padding-right: 0;
  }
  .navbar a:not([href]):not([tabindex]),
  .homenavbar a:not([href]):not([tabindex]) {
    display: inline;
    text-decoration: none;
    color: white;
  }
  
  .navbar a:not([href]):not([tabindex]),
  .homenavbar a:not([href]):not([tabindex]) {
    background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0)
      3px no-repeat;
    transition: 0.5s, background-position 0s 0.3s;
  }
  
  .navbar a:not([href]):not([tabindex]):hover,
  .homenavbar a:not([href]):not([tabindex]):hover {
    text-decoration: none;
    /* color: #fcd365; */
    --d: 100%;
    --p: 100%;
  }
  
  .navbar .nav_item,
  .homenavbar .nav_item {
    display: inline;
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  
  .navbar .nav_item,
  .homenavbar .nav_item {
    background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0)
      3px no-repeat;
      color: white;
    transition: 0.5s, background-position 0s 0.3s;
  }
  
  .navbar .nav_item:hover,
  .homenavbar .nav_item:hover {
    text-decoration: none;
    color: #fcd365;
    --d: 100%;
    --p: 100%;
  }
}
