* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.banner {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  background: black;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.165);
  scroll-behavior: smooth;
  scrollbar-width: thin;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 20px;
  bottom: 40px;
  transform: translateZ(-11px) scale(2.2);
  z-index: 10;
  animation: enter;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes enter {
  0% {
    transform: translateZ(-11px) translateY(400px) scale(2.2);
    opacity: 0;
  }
  100% {
    transform: translateZ(-11px) translateY(0px) scale(2.2);
    opacity: 1;
  }
}
.bulb {
  width: calc(20vw + 60px);
  /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
  filter: sepia(70%) saturate(170%) brightness(90%);
  /* transform: translateY(calc(-5vw-20px)); */
  animation: bulb;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes bulb {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

.title {
  width: calc(48vw + 50px);
  transform: translateX(-5vw) translateY(3vw);
  filter: brightness(200%);
  animation: title;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes title {
  0% {
    transform: translateX(-5vw) translateY(calc(3vw - 4px));
  }
  100% {
    transform: translateX(-5vw) translateY(calc(3vw + 4px));
  }
}
.background {
  transform: translateZ(-40px) scale(5);
  animation-name: pulse;
  animation-duration: 10s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-iteration-count: infinite;
  filter: brightness(80%) saturate(120%);
}
@keyframes pulse {
  50% {
    /* opacity: 0.5; */
    filter: brightness(40%) saturate(180%);
  }
}

.middle1 {
  transform: translateZ(-30px) scale(4);
  filter: brightness(80%);
  animation: den;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes den {
  0% {
    transform: translateZ(-30px) translateY(-10px) scale(4);
  }
  100% {
    transform: translateZ(-30px) translateY(0px) scale(4);
  }
}
.middle2 {
  transform: translateZ(-20px) scale(3);
  animation: castle;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes castle {
  0% {
    transform: translateZ(-20px) translateY(-5px) scale(3);
  }
  100% {
    transform: translateZ(-20px) translateY(5px) scale(3);
  }
}
.middle3 {
  transform: translateZ(-10px) scale(2);
  animation: building;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes building {
  0% {
    transform: translateZ(-10px) translateY(7px) scale(2);
  }
  100% {
    transform: translateZ(-10px) translateY(-7px) scale(2);
  }
}

.middle4 {
  transform: translateZ(-5px) translateY(-32px) scale(1.6);
  animation: warrior;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes warrior {
  0% {
    transform: translateZ(-5px) translateY(-35px) scale(1.6);
  }
  100% {
    transform: translateZ(-5px) translateY(-29px) scale(1.6);
  }
}

.foreground {
  transform: translateZ(-2px) scale(1.2);
}

.foreground,
.background,
.middle {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.middle1 {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.middle2 {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.middle3 {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.middle4 {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.foreground {
  filter: brightness(40%);
}
.foreground2 {
  position: absolute;
  top: 100vh;
  height: 200vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
}
header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.loader {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  display: flex;
  width: 100%;
  z-index: 100;
  flex-direction: column;
  ;
}

.cloud {
  filter: brightness(20%);
  position: absolute;

  opacity: 1;
  transition: 0.3s ease;

  /* animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-iteration-count: infinite; */
}
.cloud2 {
  filter: brightness(20%);
  position: absolute;
  opacity: 1;
  transition: 3s ease;

  /* animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-iteration-count: infinite; */
}
/* @keyframes pulse {
  50% {
    opacity: 0.7;
  }
} */
.cloud2:hover {
  scale: 1.1;
  transition: 0.7s ease;
}

.font {
  width: 300px;
  height: 400px;
  filter: saturate(150%) brightness(110%);
}


.aboutusbg{
 background: url("../images/content/redstone.png");
  background-position: center center;
background-repeat: no-repeat;
background-size: cover;


}
.eventsbg{
  background: url("../images/content/events.webp");
   background-position: center center;
   background-repeat: no-repeat;
background-size: cover;
   
 
 }
 .speakersbg{
  background: url("../images/content/speakerbg.jpeg");
   background-position: center center;
   background-repeat: no-repeat;
background-size: cover;
   
 
 }