.title {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: "Gambetta", serif;
  letter-spacing: -3px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color: PaleGoldenRod;
  outline: none;
  text-align: center;
  word-spacing: 1.2rem;
  line-height: 3rem;
}

.title:hover {
  font-variation-settings: "wght" 582;
  letter-spacing: 1px;
}

.themeDiv {
  background: rgba(222, 87, 60, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 10px;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
