@import url("https://fonts.googleapis.com/css2?family=Playwrite+PT:wght@100..400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300..700&family=Playwrite+PT:wght@100..400&display=swap");
.playtime {
  font-family: Playwrite PT;
}

.jura {
  font-family: Jura;
  transition: 0.3s ease;
}
.cardContainer {
  display: flex;
  gap: 50px;
  width: 90%;
  justify-content: space-around;
  flex-wrap: wrap;
}
a:hover {
  text-decoration: none;
}
.card {
  perspective: 150rem;
  position: relative;
  height: 450px;
  width: 300px;

  box-shadow: none;
  background: none;
  border-radius: 10px;
}

.cardside {
  height: 100%;
  border-radius: 15px;
  transition: all 0.8s ease;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  background: linear-gradient(to bottom, #fa942e9d, #e4392aa6);
  color: white;
  border-radius: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardside.back {
  transform: rotateY(-180deg);
  /* background: #fff; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 1.3rem;
  overflow: hidden;
  text-align: justify;
  padding: 15px;
  /* border-top: 4px solid #0073e6; */
}

.cardside.front {
  /* background: #fff; */
  text-transform: uppercase;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  font-size: 1.5rem;
  /* border-bottom: 4px solid #0073e696; */
}

.card:hover .cardside.front {
  transform: rotateY(180deg);
}

.card:hover .cardside.back {
  transform: rotateY(0deg);
}

.scroll {
  scrollbar-width: 0;
  scrollbar-color: transparent transparent;
}
/* carosal */
.carouselContainer {
  height: 400px;
  display: grid;
  width: 100%;
  place-items: center;
  overflow: hidden;
  position: relative;
  margin: auto;
  column-gap: 25px;
}

.carouselTrack {
  display: flex;
  width: 10000px;
  transition: transform 1s linear;
  animation: scroll 16s linear infinite alternate-reverse;
  padding: 0;
  gap: 20px;
}

.carouselTrack:hover {
  animation-play-state: paused;
}

.carouselTrack:hover .img {
  border-radius: 50%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-2800px);
  }
}

.carouselSlide {
  height: 0;
  width: 175px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0;
  perspective: 200px;
}

.carouselSlide:hover {
  filter: drop-shadow(0px 6px 3px #b9372b72);
}

.imgdn {
  top: 31px;
  right: 0;
  border-radius: 10px;
  animation-name: down;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
}

.imgdn,
.imgup {
  width: 175px;
  height: 175px;
  position: relative;
  margin: 0;
  border-radius: 40%;
  transition: 1s ease;
  border: 7px solid #c16b15;
}
.imgdn:hover,
.imgup:hover {
  scale: 1.1;
  transition: 0s ease;
  border: 0px;
}

.imgup {
  bottom: 31px;
  animation-name: up;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
}

.streak {
  transition: 1s ease;
  backdrop-filter: blur(50px);
  border: 2px solid #c16b15;
  background-color: transparent;
}

.streak:hover {
  border: 5px solid #bc271a;
  transition: 0s ease;
}

.rotatingchild {
  transition: 0.3s ease;
}

.hoverparent:hover .rotatingchild {
  transform: rotateZ(345deg);
}

.rotatingchild:hover {
  transform: rotateZ(345deg);
}
