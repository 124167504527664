.body {
  padding-top: 5%;
  background-color: black;
}

.text1 {
  /* margin-top:15%;*/
  color: white;
  text-align: center;
  /* margin-top:5%; */
  font-size: x-large;
  font-weight: 500;
  padding-top: 3%;
}

.image {
  width: 100%;
  height: 100%;
  margin-right: 5%;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 4%;
}

.content {
  padding: 2%;
  font-size: large;
  font-weight: bold;
  color: white;
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  height: auto;
  margin-right: 3%;
  margin-left: 3%;
  padding-left: 4.5%;
  padding-right: 4.5%;
  padding-bottom: 0%;
  padding-top: 0%;
}

.Gridchild {
  position: relative;
  width: 100%; /* One item per row by default */
  margin-bottom: 9%;
  margin-top:10%; /* Adjust spacing between rows */
}

/* Responsive styles for screens between 500px and 1000px */
@media screen and (min-width: 500px) and (max-width: 600px) {
  .Grid{
    margin-bottom:0%;
    margin-top: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .Gridchild {
    width: 48%; /* Two items per row */
    margin-right: 2%;
    margin-bottom:7%;
    margin-top: 6%;
    padding-top: 0%; /* Adjust spacing between items */
  }
  .content{
    font-size: large;
  }
  .text1{
    font-size: x-large;
  }
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .Grid{
    margin-bottom:0%;
    margin-top: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .Gridchild {
    width: 48%; /* Two items per row */
    margin-right: 2%;
    margin-bottom:5%;
    margin-top: 4%;
    padding-top: 0%; /* Adjust spacing between items */
  }
  .content{
    font-size: x-large;
  }
  .text1{
    font-size: xx-large;
  }
}
/* Responsive styles for screens larger than 1000px */
@media screen and (min-width: 1000px) {
  .Grid{
    margin-bottom:0%;
    margin-top: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
  }
  .Gridchild {
    width: 26%; /* Three items per row */
    margin-right: 5%;
    margin-bottom:6%;
    margin-top: 5%;
    padding-top: 0%;
     /* Adjust spacing between items */
  }
  .content{
    font-size: x-large;
    padding: 4%;
  }
  .text1{
    font-size: xxx-large;
    padding: 4%;
  }
}
