.backgrounds{
    background-size: cover;
    padding-bottom: 10%;
}

.div{
    display: flex;
    flex-direction: column;
}
.icon{
   padding-left: 12%;
   font-size: x-large;
   font-family: Nunito Sans;
}
.data{
    font-size: x-large;
    font-family: Nunito Sans;
}

.heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-block: 1rem;
    
  }
  
  .title {
    background: -webkit-linear-gradient(45deg, rgba(195,146,52) ,rgb(169, 160, 97), rgba(195,146,52));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3rem;
    transition: .4s ease;
   
    
  }
  
  @keyframes spring-bounce {
    from {
        transform: translateX(0px) scale(1);
    }
    to {
        transform: translateX(10px) scale(1.09);
        
    }
}
  
  .title:hover{
    
    /* letter-spacing: 0.75rem; */
    /* transform: translateX(7px); */
    filter: brightness(140%);
    animation: spring-bounce 0.5s alternate;
    /* text-decoration: underline; */
    /* text-decoration-color: rgb(169, 160, 97);
    text-decoration-thickness: 2px;
    text-underline-offset: 10px; */
  }