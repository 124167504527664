.counterDiv {
  border-style: solid;
  border-width: 0.4rem;
  -webkit-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
  -moz-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
  box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);

  animation: changeShadowColor 10s infinite;
  background: -webkit-linear-gradient(
    45deg,
    rgb(104, 77, 26),
    rgb(90, 85, 51),
    rgb(113, 82, 25)
  );
  filter: brightness(90%);
}

@keyframes changeShadowColor {
  0% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    -moz-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    border-color: rgba(195, 146, 52, 0.8);
  }
  12.5% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.5);
    -moz-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.5);
    box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.5);
    border-color: rgba(195, 146, 52, 0.4);
  }
  25% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.9);
    -moz-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.9);
    box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.9);
    border-color: rgba(169, 160, 97, 0.8);
  }
  37.5% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.5);
    -moz-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.5);
    box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.5);
    border-color: rgba(169, 160, 97, 0.4);
  }
  50% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    -moz-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    border-color: rgba(195, 146, 52, 0.8);
  }
  62.5% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.5);
    -moz-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.5);
    box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.5);
    border-color: rgba(195, 146, 52, 0.4);
  }
  75% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.9);
    -moz-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.9);
    box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.9);
    border-color: rgba(169, 160, 97, 0.8);
  }
  87.5% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.5);
    -moz-box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.5);
    box-shadow: 0px 0px 76px 25px rgba(169, 160, 97, 0.5);
    border-color: rgba(169, 160, 97, 0.4);
  }
  100% {
    -webkit-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    -moz-box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    box-shadow: 0px 0px 76px 25px rgba(195, 146, 52, 0.9);
    border-color: rgba(195, 146, 52, 0.8);
  }
}
