* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

.banner {
    /* background-image: url('../../public/esummit_banner.png'); */
    /* background-size: cover; Adjust to your preference */
    /* background-repeat: no-repeat;
    background-position: center center; */
    height: 100.5vh; /* You can adjust the height as needed */

    /* background-video: url('../../public/bgAni.mp4'); */
}

video {

    width: 100%;
    height: 101vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -2;
    /* position: fixed; */
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.bannerContent {

    /* padding: 15%; */

    padding: 15%;

    /* position: fixed; */
    /* top: 30%; */

    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.banner_date {
    font-weight: 500;
    font-size: 3rem;
    font-family: Nunito Sans;
    color: #a35fce;
}

.registerBtn {
    padding: 1% 3%;
    width: fit-content;
    border-radius: 15px;
    background-color: #ac40d650;
    backdrop-filter: blur(2px);
    border: 1.5px solid #ab5ada;
    margin: 5% auto;
    /* width: 20%; */
    font-weight: 700;
    font-size: 1.3rem;
    font-family: Nunito Sans;
    cursor: pointer;
}

.logoImg{
    height: 35vh;
}

@media (max-width : 900px) {
    .bannerContent {
        padding: 45% 0 15% 11%;
    }

    .banner_date {
        padding: 10% 5% 0 2%;
    }
}

@media (max-width : 500px) {
    .bannerContent{
        padding: 10% 0 0 10%;
    }

    .banner_date{
        font-size: 2.5rem;
    }
}