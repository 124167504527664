@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    opacity: 0;
    animation: fadeInUp 1s forwards;
  }
  
  .animate {
    animation: fadeInUp 1s forwards;
  }
  
  
  .emailbutton {
    /* margin: 0px auto;
    margin-bottom: 30px; /* Center align using auto margins */
    /* padding: 15px 30px; */ 
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    /* display: block; */
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .emailbutton:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
  
  emailbutton:active {
    transform: scale(0.95);
  }
  
  