.aboutBackground {
  width: 100vw;
  background: hsla(0, 0%, 0%, 1);

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#511C1E", GradientType=1 );
}

.title {
  font-size: 10rem;
  text-transform: uppercase;
  letter-spacing: 10px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  min-width: 700px;
  outline: none;
  text-align: center;
  word-spacing: 1rem;

  background: url("../images/content/redstone.png");
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: brighten;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
  font-variation-settings: "wght" 582;
  text-shadow: inset 0px 0px 0px white;
}

@keyframes brighten {
  0% {
    filter: brightness(130%) saturate(120%);
    letter-spacing: 10px;
  }
  100% {
    filter: brightness(180%) saturate(120%);
    letter-spacing: 15px;
  }
}
.scroll {
  filter: brightness(40%) sepia(50%) saturate(160%);
}
.aboutDiv {
  border-radius: 10px;
}
