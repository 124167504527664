.theme-div {
  width: 100vw;
  background: hsla(0, 0%, 0%, 1);

  background: linear-gradient(
    45deg,
    hsla(0, 0%, 0%, 1) 0%,
    hsla(358, 49%, 21%, 1) 74%
  );

  background: -moz-linear-gradient(
    45deg,
    hsla(0, 0%, 0%, 1) 0%,
    hsla(358, 49%, 21%, 1) 74%
  );

  background: -webkit-linear-gradient(
    45deg,
    hsla(0, 0%, 0%, 1) 0%,
    hsla(358, 49%, 21%, 1) 74%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#511C1E", GradientType=1 );
  /* height: 95vh; */
  padding-bottom: 5vh;
}

.theme {
  overflow: hidden;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0);
  /* background: #000000;
  background: -webkit-linear-gradient(128deg, #641EA9 0%, #22246A 50%, #781aaf 100%);
  background: linear-gradient(128deg, #B614A9 0%, #010715 50%, #0C2D61 100%); */
  height: 95vh;
  /* padding-bottom: 10%; */
}

.theme_title {
  padding-top: 85px;
  color: #817eff;
  text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1rem;
}

.theme_text {
  position: relative;
  z-index: 1000;
  background: rgba(217, 217, 217, 0.26);
  /* backdrop-filter: blur(8.0px); */
  margin: 50px;

  border-radius: 50px;
  border: 2px rgba(194, 101, 73, 0.5) solid;
}

.theme_heading {
  font-family: Poppins;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1rem;
  color: rgba(222, 87, 60, 0.85);
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.621);
}

@media (max-width: 400px) {
  .theme_title {
    font-size: 3.5rem;
  }

  .theme-div {
    /* height: 125vh; */
  }

  .theme {
    height: 125vh;
  }
}

@media (max-width: 600px) {
  .theme_heading {
    font-size: 2rem;
  }

  .theme_text {
    /* background-color: rgba(255, 255, 255, 0.486); */
    margin: 25px;
    font-size: large;
    padding-bottom: 10%;
  }
}

@media (max-width: 1100px) {
  .theme {
    transform: scaleY(5vh);
  }
}
.text_p {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  line-height: 2;
  padding-bottom: 5%;
  color: white;
}

.theme_img {
  z-index: 0;
  position: absolute;
  left: 10%;
  width: 12%;
  opacity: 0.5;
  height: auto;
}

@media (max-width: 800px) {
  .theme {
    height: 115vh;
  }

  .theme-div {
    /* height: 115vh; */
  }
}

@media (min-width: 800px) {
  .theme_text {
    /* background-color: rgba(255, 255, 255, 0.486); */
    height: 60%;
    margin: 50px auto;
    width: 70%;
  }

  .theme_img {
    width: 10%;
  }
}

.theme1 {
  z-index: 10000;
  position: absolute;
  left: 0%;
  top: 10%;
  transform: rotate(195deg);
}

.theme2 {
  left: 60%;
  top: -20%;
  transform: rotate(15deg);
}

.theme3 {
  left: -8%;
  top: 67%;
  transform: rotate(213deg);
}

.theme4 {
  left: 30%;
  top: 54%;
  transform: rotate(337deg);
}

.theme5 {
  left: 50%;
  top: 20%;
  transform: rotate(35deg);
}

.theme6 {
  top: 12%;
  left: 88%;
  transform: rotate(60deg);
}

.theme7 {
  top: 66%;
  left: 87%;
  transform: rotate(45deg);
}
.theme8 {
  top: 80%;
  left: 68%;
  transform: rotate(235deg);
}
.theme9 {
  top: 80%;
  left: 8%;
  transform: rotate(245deg);
}

@media (width: 1024px) {
  .theme-div {
    /* height: 110vh; */
  }
  .theme {
    height: 110vh;
  }
  .theme_text {
    height: 65%;
  }
}

/* Background effext */

.stars-div {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #342426 0%, #090a0f 100%);
  overflow: hidden;
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 721px 23px #fff, 849px 1473px #fff, 721px 395px #fff,
    775px 27px #fff, 914px 1101px #fff, 582px 609px #fff, 1108px 96px #fff,
    547px 1288px #fff, 1628px 156px #fff, 1114px 636px #fff, 1029px 473px #fff,
    16px 1327px #fff, 416px 31px #fff, 1636px 157px #fff, 1426px 694px #fff,
    1219px 308px #fff, 1445px 1876px #fff, 136px 1345px #fff, 1292px 927px #fff,
    705px 1392px #fff, 1286px 1250px #fff, 911px 737px #fff, 1980px 91px #fff,
    1449px 1538px #fff, 377px 1376px #fff, 452px 84px #fff, 648px 818px #fff,
    51px 391px #fff, 1688px 440px #fff, 1888px 1743px #fff, 1802px 1812px #fff,
    1711px 289px #fff, 402px 247px #fff, 634px 1248px #fff, 1592px 1238px #fff,
    889px 558px #fff, 1801px 116px #fff, 349px 1467px #fff, 1627px 1032px #fff,
    469px 1791px #fff, 675px 278px #fff, 911px 1917px #fff, 1942px 978px #fff,
    1912px 72px #fff, 1956px 159px #fff, 1473px 1229px #fff, 1609px 821px #fff,
    506px 1993px #fff, 1925px 1266px #fff, 654px 165px #fff, 360px 1158px #fff,
    1077px 1423px #fff, 754px 1157px #fff, 105px 602px #fff, 29px 793px #fff,
    551px 115px #fff, 1676px 1152px #fff, 188px 465px #fff, 1678px 1359px #fff,
    1159px 837px #fff, 204px 475px #fff, 1445px 430px #fff, 1388px 1655px #fff,
    96px 1076px #fff, 1664px 1939px #fff, 392px 1124px #fff, 929px 1724px #fff,
    38px 255px #fff, 1957px 1647px #fff, 675px 95px #fff, 1805px 440px #fff,
    1416px 29px #fff, 768px 867px #fff, 1529px 239px #fff, 1088px 1861px #fff,
    1296px 1619px #fff, 491px 146px #fff, 1px 1085px #fff, 1690px 13px #fff,
    740px 853px #fff, 846px 176px #fff, 273px 64px #fff, 299px 973px #fff,
    1880px 395px #fff, 924px 1019px #fff, 1431px 1797px #fff, 1618px 789px #fff,
    952px 1695px #fff, 686px 1955px #fff, 1603px 1907px #fff, 1382px 1683px #fff,
    1569px 997px #fff, 1371px 686px #fff, 1895px 711px #fff, 825px 205px #fff,
    220px 599px #fff, 696px 1797px #fff, 578px 1162px #fff, 1652px 1639px #fff,
    1631px 1518px #fff, 423px 874px #fff, 1895px 1621px #fff, 1099px 171px #fff,
    1833px 912px #fff, 588px 1737px #fff, 1870px 1967px #fff, 481px 1912px #fff,
    1232px 1184px #fff, 222px 261px #fff, 957px 862px #fff, 218px 909px #fff,
    377px 1455px #fff, 163px 410px #fff, 1732px 161px #fff, 1334px 463px #fff,
    864px 1923px #fff, 1588px 116px #fff, 564px 1927px #fff, 122px 171px #fff,
    1869px 675px #fff, 416px 635px #fff, 1271px 1710px #fff, 572px 218px #fff,
    461px 1191px #fff, 746px 1114px #fff, 1533px 1426px #fff, 1750px 1257px #fff,
    1612px 927px #fff, 1849px 638px #fff, 1937px 1077px #fff, 64px 116px #fff,
    1789px 611px #fff, 884px 1460px #fff, 1428px 993px #fff, 624px 1734px #fff,
    1090px 770px #fff, 1842px 425px #fff, 1176px 925px #fff, 1448px 438px #fff,
    1252px 1307px #fff, 1572px 799px #fff, 1580px 1014px #fff, 749px 1240px #fff,
    1633px 1418px #fff, 1137px 278px #fff, 1434px 334px #fff, 1816px 1955px #fff,
    402px 670px #fff, 778px 1687px #fff, 618px 1199px #fff, 336px 804px #fff,
    1051px 685px #fff, 982px 496px #fff, 446px 1947px #fff, 277px 1468px #fff,
    1111px 351px #fff, 1384px 141px #fff, 902px 1724px #fff, 1825px 1076px #fff,
    1873px 608px #fff, 118px 1515px #fff, 930px 939px #fff, 1074px 4px #fff,
    1870px 203px #fff, 1364px 706px #fff, 587px 445px #fff, 275px 1859px #fff,
    405px 1521px #fff, 619px 588px #fff, 1451px 184px #fff, 1873px 99px #fff,
    1281px 1499px #fff, 1631px 1864px #fff, 1148px 1221px #fff,
    918px 1121px #fff, 1881px 1457px #fff, 149px 1919px #fff, 154px 1255px #fff,
    1676px 1148px #fff, 852px 190px #fff, 1108px 842px #fff, 1132px 887px #fff,
    1334px 1012px #fff, 1176px 1970px #fff, 259px 104px #fff, 992px 237px #fff,
    372px 326px #fff, 1819px 935px #fff, 992px 453px #fff, 1257px 1617px #fff,
    1760px 1193px #fff, 1807px 62px #fff, 1634px 465px #fff, 423px 1714px #fff,
    1764px 764px #fff, 479px 798px #fff, 851px 737px #fff, 256px 1076px #fff,
    1899px 1408px #fff, 1965px 1495px #fff, 158px 951px #fff, 1425px 1428px #fff,
    208px 1241px #fff, 1452px 844px #fff, 1823px 1207px #fff, 1874px 1454px #fff,
    1043px 1180px #fff, 1166px 1704px #fff, 984px 1215px #fff, 1588px 198px #fff,
    537px 1771px #fff, 1496px 718px #fff, 862px 741px #fff, 129px 1285px #fff,
    1906px 1732px #fff, 772px 1621px #fff, 175px 562px #fff, 1739px 995px #fff,
    15px 1923px #fff, 899px 1242px #fff, 405px 903px #fff, 1584px 1926px #fff,
    1323px 430px #fff, 92px 447px #fff, 956px 873px #fff, 583px 1615px #fff,
    1884px 990px #fff, 769px 1710px #fff, 422px 1378px #fff, 1006px 76px #fff,
    480px 198px #fff, 53px 1312px #fff, 1100px 119px #fff, 931px 1330px #fff,
    300px 188px #fff, 1592px 924px #fff, 691px 1831px #fff, 1378px 234px #fff,
    1536px 1130px #fff, 193px 1524px #fff, 1147px 513px #fff, 664px 600px #fff,
    804px 184px #fff, 700px 538px #fff, 1337px 650px #fff, 1716px 269px #fff,
    1711px 1096px #fff, 420px 1764px #fff, 1884px 640px #fff, 517px 1195px #fff,
    177px 1579px #fff, 790px 1880px #fff, 587px 780px #fff, 386px 1615px #fff,
    1773px 1262px #fff, 285px 1884px #fff, 1447px 1010px #fff, 385px 1111px #fff,
    1680px 290px #fff, 534px 429px #fff, 1407px 1577px #fff, 1499px 874px #fff,
    1372px 1786px #fff, 1710px 1765px #fff, 1826px 258px #fff, 538px 142px #fff,
    49px 887px #fff, 1428px 223px #fff, 672px 1873px #fff, 916px 919px #fff,
    1585px 1540px #fff, 1977px 1348px #fff, 1782px 1561px #fff,
    1735px 1749px #fff, 960px 1288px #fff, 47px 1000px #fff, 1734px 632px #fff,
    1140px 1052px #fff, 625px 783px #fff, 1042px 681px #fff, 721px 522px #fff,
    1061px 10px #fff, 1651px 1802px #fff, 660px 880px #fff, 1448px 838px #fff,
    559px 1407px #fff, 1361px 1872px #fff, 927px 1243px #fff, 966px 1762px #fff,
    1544px 982px #fff, 835px 1696px #fff, 909px 1683px #fff, 794px 1004px #fff,
    27px 1857px #fff, 1393px 144px #fff, 1592px 391px #fff, 1757px 1878px #fff,
    1024px 151px #fff, 1799px 972px #fff, 958px 1752px #fff, 1253px 818px #fff,
    315px 521px #fff, 1320px 399px #fff, 1918px 486px #fff, 1600px 1538px #fff,
    1662px 1630px #fff, 420px 708px #fff, 189px 299px #fff, 243px 100px #fff,
    1484px 1184px #fff, 1851px 1557px #fff, 611px 1421px #fff, 1892px 39px #fff,
    33px 1704px #fff, 1320px 791px #fff, 1127px 1028px #fff, 1827px 1486px #fff,
    1448px 1545px #fff, 1878px 1200px #fff, 1422px 738px #fff, 1306px 697px #fff,
    1038px 833px #fff, 1374px 666px #fff, 1192px 1804px #fff, 1945px 1843px #fff,
    1039px 687px #fff, 1565px 397px #fff, 31px 273px #fff, 529px 604px #fff,
    1994px 1838px #fff, 296px 1397px #fff, 1062px 1052px #fff,
    1321px 1358px #fff, 1262px 1407px #fff, 1859px 1736px #fff, 536px 728px #fff,
    9px 1179px #fff, 1911px 565px #fff, 246px 448px #fff, 1953px 1723px #fff,
    1947px 687px #fff, 1376px 816px #fff, 1407px 248px #fff, 576px 1748px #fff,
    269px 710px #fff, 409px 1892px #fff, 4px 1168px #fff, 1570px 115px #fff,
    1544px 580px #fff, 270px 501px #fff, 1641px 509px #fff, 1903px 931px #fff,
    740px 509px #fff, 265px 1097px #fff, 1801px 1640px #fff, 598px 1959px #fff,
    1642px 1972px #fff, 1689px 1247px #fff, 1399px 147px #fff, 667px 1947px #fff,
    96px 635px #fff, 1412px 973px #fff, 1753px 1107px #fff, 1360px 1089px #fff,
    1298px 1683px #fff, 595px 52px #fff, 1006px 372px #fff, 1492px 1574px #fff,
    112px 1692px #fff, 80px 1892px #fff, 1230px 1864px #fff, 1143px 1927px #fff,
    1237px 1136px #fff, 347px 1762px #fff, 544px 1698px #fff, 536px 1825px #fff,
    115px 774px #fff, 1581px 842px #fff, 926px 1957px #fff, 425px 809px #fff,
    923px 450px #fff, 1068px 1569px #fff, 1731px 249px #fff, 1525px 1327px #fff,
    1802px 711px #fff, 886px 1863px #fff, 1199px 1174px #fff, 721px 1522px #fff,
    793px 360px #fff, 268px 898px #fff, 368px 1907px #fff, 1678px 820px #fff,
    1856px 598px #fff, 551px 1220px #fff, 450px 647px #fff, 1469px 1879px #fff,
    1966px 784px #fff, 1320px 1688px #fff, 553px 1232px #fff, 1937px 1105px #fff,
    1658px 915px #fff, 1173px 485px #fff, 1614px 69px #fff, 835px 689px #fff,
    1429px 515px #fff, 179px 139px #fff, 1263px 1596px #fff, 733px 1800px #fff,
    1687px 515px #fff, 904px 493px #fff, 1194px 1621px #fff, 1251px 1701px #fff,
    451px 602px #fff, 1190px 7px #fff, 358px 406px #fff, 1589px 842px #fff,
    329px 858px #fff, 1683px 30px #fff, 1597px 1323px #fff, 384px 1991px #fff,
    1760px 673px #fff, 1353px 1490px #fff, 286px 226px #fff, 1735px 908px #fff,
    197px 182px #fff, 304px 793px #fff, 1430px 1295px #fff, 1615px 1410px #fff,
    209px 987px #fff, 151px 1326px #fff, 1224px 794px #fff, 1415px 1252px #fff,
    1054px 1391px #fff, 41px 263px #fff, 22px 1365px #fff, 1611px 1413px #fff,
    610px 337px #fff, 1175px 1787px #fff, 874px 39px #fff, 1160px 498px #fff,
    915px 822px #fff, 1643px 202px #fff, 1404px 1410px #fff, 753px 890px #fff,
    1664px 1673px #fff, 202px 321px #fff, 816px 1319px #fff, 1869px 515px #fff,
    1107px 834px #fff, 90px 520px #fff, 1626px 1013px #fff, 1499px 481px #fff,
    376px 1018px #fff, 204px 807px #fff, 1835px 725px #fff, 1378px 1830px #fff,
    545px 1513px #fff, 562px 913px #fff, 268px 590px #fff, 1890px 1797px #fff,
    1416px 1403px #fff, 1164px 1807px #fff, 639px 1398px #fff, 1354px 719px #fff,
    2px 193px #fff, 1402px 711px #fff, 1807px 1142px #fff, 867px 1539px #fff,
    710px 1722px #fff, 366px 748px #fff, 1621px 1688px #fff, 1585px 1741px #fff,
    1212px 771px #fff, 427px 1836px #fff, 492px 915px #fff, 1588px 114px #fff,
    1871px 85px #fff, 761px 1642px #fff, 1707px 1404px #fff, 853px 1965px #fff,
    1941px 1484px #fff, 1635px 1434px #fff, 1346px 739px #fff, 187px 366px #fff,
    1755px 1065px #fff, 273px 1568px #fff, 148px 1394px #fff, 1414px 1134px #fff,
    1027px 521px #fff, 1283px 1333px #fff, 1682px 367px #fff, 645px 441px #fff,
    1159px 1701px #fff, 482px 1269px #fff, 1602px 1205px #fff,
    1984px 1581px #fff, 1302px 1632px #fff, 406px 202px #fff, 1972px 774px #fff,
    905px 132px #fff, 1879px 1184px #fff, 1004px 19px #fff, 304px 988px #fff,
    1934px 1421px #fff, 838px 1662px #fff, 1289px 986px #fff, 306px 682px #fff,
    861px 258px #fff, 1252px 1947px #fff, 851px 682px #fff, 1330px 548px #fff,
    1934px 1521px #fff, 1055px 285px #fff, 256px 1988px #fff, 1124px 626px #fff,
    1214px 1168px #fff, 339px 1345px #fff, 1754px 1915px #fff, 422px 1778px #fff,
    1035px 291px #fff, 255px 335px #fff, 1627px 127px #fff, 1952px 765px #fff,
    1658px 827px #fff, 1209px 922px #fff, 1326px 844px #fff, 511px 1423px #fff,
    552px 1383px #fff, 812px 1276px #fff, 551px 1514px #fff, 1485px 1772px #fff,
    1409px 500px #fff, 210px 1062px #fff, 1666px 388px #fff, 899px 1591px #fff,
    1826px 736px #fff, 1328px 1106px #fff, 1284px 1595px #fff, 28px 19px #fff,
    1299px 803px #fff, 1619px 54px #fff, 1732px 1909px #fff, 1751px 216px #fff,
    1243px 1676px #fff, 580px 1566px #fff, 1460px 559px #fff, 1909px 1484px #fff,
    803px 1308px #fff, 133px 1152px #fff, 1775px 1517px #fff, 697px 917px #fff,
    346px 634px #fff, 255px 671px #fff, 1090px 639px #fff, 1998px 1418px #fff,
    481px 1319px #fff, 1638px 1471px #fff, 483px 1731px #fff, 1432px 1632px #fff,
    135px 800px #fff, 1855px 1279px #fff, 1213px 1340px #fff, 899px 1975px #fff,
    896px 1989px #fff, 1434px 1449px #fff, 969px 228px #fff, 516px 1873px #fff,
    1532px 1447px #fff, 865px 1744px #fff, 1437px 764px #fff, 2px 512px #fff,
    1849px 27px #fff, 1607px 849px #fff, 1075px 1718px #fff, 1057px 1823px #fff,
    453px 1024px #fff, 1668px 1525px #fff, 879px 1543px #fff, 1526px 72px #fff,
    1964px 1934px #fff, 1650px 892px #fff, 976px 1822px #fff, 1519px 1038px #fff,
    975px 748px #fff, 1494px 409px #fff, 12px 1380px #fff, 679px 1069px #fff,
    1467px 1355px #fff, 1999px 1033px #fff, 965px 1077px #fff,
    1449px 1815px #fff, 600px 834px #fff, 1878px 1673px #fff, 1953px 1068px #fff,
    1079px 899px #fff, 1630px 1606px #fff, 803px 1720px #fff, 746px 1213px #fff,
    1210px 226px #fff, 1800px 837px #fff, 893px 625px #fff, 1672px 845px #fff,
    1588px 543px #fff, 1027px 368px #fff, 1229px 993px #fff, 1856px 1776px #fff,
    798px 1151px #fff, 934px 1909px #fff, 1920px 747px #fff, 1513px 743px #fff,
    889px 1283px #fff, 263px 777px #fff, 785px 1578px #fff, 1438px 702px #fff,
    1854px 1022px #fff, 622px 987px #fff, 1502px 1200px #fff, 627px 896px #fff,
    1417px 830px #fff, 781px 1399px #fff, 1640px 1070px #fff, 1917px 1994px #fff,
    229px 622px #fff, 80px 821px #fff, 1914px 1805px #fff, 616px 546px #fff,
    680px 720px #fff, 1028px 1395px #fff, 681px 783px #fff, 381px 513px #fff,
    709px 1855px #fff, 1529px 1786px #fff, 1105px 1566px #fff,
    1479px 1927px #fff, 1701px 505px #fff, 543px 1840px #fff, 712px 654px #fff,
    1466px 1302px #fff, 1412px 72px #fff, 410px 1443px #fff, 1926px 689px #fff,
    1945px 147px #fff, 1586px 417px #fff, 1776px 963px #fff, 1390px 1214px #fff,
    1250px 16px #fff, 196px 44px #fff, 497px 882px #fff, 1287px 24px #fff,
    1569px 1141px #fff, 1633px 1008px #fff, 373px 890px #fff, 422px 1737px #fff,
    591px 6px #fff, 1576px 1561px #fff, 602px 546px #fff, 819px 1179px #fff,
    33px 1392px #fff, 1629px 114px #fff, 1796px 362px #fff, 232px 366px #fff,
    1763px 760px #fff, 1023px 101px #fff, 951px 1628px #fff, 921px 1940px #fff,
    907px 1966px #fff, 127px 762px #fff, 1381px 33px #fff, 1788px 574px #fff,
    1702px 889px #fff, 1701px 1283px #fff, 1854px 1906px #fff, 1391px 518px #fff,
    977px 1386px #fff, 1503px 1054px #fff, 1773px 1916px #fff, 1297px 899px #fff,
    1533px 1985px #fff, 220px 6px #fff, 1790px 1037px #fff, 1270px 315px #fff,
    1944px 1080px #fff, 403px 967px #fff, 1545px 398px #fff, 1318px 172px #fff,
    1696px 392px #fff, 1871px 1679px #fff, 549px 1321px #fff, 505px 456px #fff,
    99px 436px #fff, 257px 314px #fff, 1015px 1039px #fff, 1257px 1302px #fff,
    1137px 545px #fff, 1370px 199px #fff, 1811px 733px #fff, 1593px 658px #fff,
    685px 917px #fff, 22px 1535px #fff, 1382px 319px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 721px 23px #fff, 849px 1473px #fff, 721px 395px #fff,
    775px 27px #fff, 914px 1101px #fff, 582px 609px #fff, 1108px 96px #fff,
    547px 1288px #fff, 1628px 156px #fff, 1114px 636px #fff, 1029px 473px #fff,
    16px 1327px #fff, 416px 31px #fff, 1636px 157px #fff, 1426px 694px #fff,
    1219px 308px #fff, 1445px 1876px #fff, 136px 1345px #fff, 1292px 927px #fff,
    705px 1392px #fff, 1286px 1250px #fff, 911px 737px #fff, 1980px 91px #fff,
    1449px 1538px #fff, 377px 1376px #fff, 452px 84px #fff, 648px 818px #fff,
    51px 391px #fff, 1688px 440px #fff, 1888px 1743px #fff, 1802px 1812px #fff,
    1711px 289px #fff, 402px 247px #fff, 634px 1248px #fff, 1592px 1238px #fff,
    889px 558px #fff, 1801px 116px #fff, 349px 1467px #fff, 1627px 1032px #fff,
    469px 1791px #fff, 675px 278px #fff, 911px 1917px #fff, 1942px 978px #fff,
    1912px 72px #fff, 1956px 159px #fff, 1473px 1229px #fff, 1609px 821px #fff,
    506px 1993px #fff, 1925px 1266px #fff, 654px 165px #fff, 360px 1158px #fff,
    1077px 1423px #fff, 754px 1157px #fff, 105px 602px #fff, 29px 793px #fff,
    551px 115px #fff, 1676px 1152px #fff, 188px 465px #fff, 1678px 1359px #fff,
    1159px 837px #fff, 204px 475px #fff, 1445px 430px #fff, 1388px 1655px #fff,
    96px 1076px #fff, 1664px 1939px #fff, 392px 1124px #fff, 929px 1724px #fff,
    38px 255px #fff, 1957px 1647px #fff, 675px 95px #fff, 1805px 440px #fff,
    1416px 29px #fff, 768px 867px #fff, 1529px 239px #fff, 1088px 1861px #fff,
    1296px 1619px #fff, 491px 146px #fff, 1px 1085px #fff, 1690px 13px #fff,
    740px 853px #fff, 846px 176px #fff, 273px 64px #fff, 299px 973px #fff,
    1880px 395px #fff, 924px 1019px #fff, 1431px 1797px #fff, 1618px 789px #fff,
    952px 1695px #fff, 686px 1955px #fff, 1603px 1907px #fff, 1382px 1683px #fff,
    1569px 997px #fff, 1371px 686px #fff, 1895px 711px #fff, 825px 205px #fff,
    220px 599px #fff, 696px 1797px #fff, 578px 1162px #fff, 1652px 1639px #fff,
    1631px 1518px #fff, 423px 874px #fff, 1895px 1621px #fff, 1099px 171px #fff,
    1833px 912px #fff, 588px 1737px #fff, 1870px 1967px #fff, 481px 1912px #fff,
    1232px 1184px #fff, 222px 261px #fff, 957px 862px #fff, 218px 909px #fff,
    377px 1455px #fff, 163px 410px #fff, 1732px 161px #fff, 1334px 463px #fff,
    864px 1923px #fff, 1588px 116px #fff, 564px 1927px #fff, 122px 171px #fff,
    1869px 675px #fff, 416px 635px #fff, 1271px 1710px #fff, 572px 218px #fff,
    461px 1191px #fff, 746px 1114px #fff, 1533px 1426px #fff, 1750px 1257px #fff,
    1612px 927px #fff, 1849px 638px #fff, 1937px 1077px #fff, 64px 116px #fff,
    1789px 611px #fff, 884px 1460px #fff, 1428px 993px #fff, 624px 1734px #fff,
    1090px 770px #fff, 1842px 425px #fff, 1176px 925px #fff, 1448px 438px #fff,
    1252px 1307px #fff, 1572px 799px #fff, 1580px 1014px #fff, 749px 1240px #fff,
    1633px 1418px #fff, 1137px 278px #fff, 1434px 334px #fff, 1816px 1955px #fff,
    402px 670px #fff, 778px 1687px #fff, 618px 1199px #fff, 336px 804px #fff,
    1051px 685px #fff, 982px 496px #fff, 446px 1947px #fff, 277px 1468px #fff,
    1111px 351px #fff, 1384px 141px #fff, 902px 1724px #fff, 1825px 1076px #fff,
    1873px 608px #fff, 118px 1515px #fff, 930px 939px #fff, 1074px 4px #fff,
    1870px 203px #fff, 1364px 706px #fff, 587px 445px #fff, 275px 1859px #fff,
    405px 1521px #fff, 619px 588px #fff, 1451px 184px #fff, 1873px 99px #fff,
    1281px 1499px #fff, 1631px 1864px #fff, 1148px 1221px #fff,
    918px 1121px #fff, 1881px 1457px #fff, 149px 1919px #fff, 154px 1255px #fff,
    1676px 1148px #fff, 852px 190px #fff, 1108px 842px #fff, 1132px 887px #fff,
    1334px 1012px #fff, 1176px 1970px #fff, 259px 104px #fff, 992px 237px #fff,
    372px 326px #fff, 1819px 935px #fff, 992px 453px #fff, 1257px 1617px #fff,
    1760px 1193px #fff, 1807px 62px #fff, 1634px 465px #fff, 423px 1714px #fff,
    1764px 764px #fff, 479px 798px #fff, 851px 737px #fff, 256px 1076px #fff,
    1899px 1408px #fff, 1965px 1495px #fff, 158px 951px #fff, 1425px 1428px #fff,
    208px 1241px #fff, 1452px 844px #fff, 1823px 1207px #fff, 1874px 1454px #fff,
    1043px 1180px #fff, 1166px 1704px #fff, 984px 1215px #fff, 1588px 198px #fff,
    537px 1771px #fff, 1496px 718px #fff, 862px 741px #fff, 129px 1285px #fff,
    1906px 1732px #fff, 772px 1621px #fff, 175px 562px #fff, 1739px 995px #fff,
    15px 1923px #fff, 899px 1242px #fff, 405px 903px #fff, 1584px 1926px #fff,
    1323px 430px #fff, 92px 447px #fff, 956px 873px #fff, 583px 1615px #fff,
    1884px 990px #fff, 769px 1710px #fff, 422px 1378px #fff, 1006px 76px #fff,
    480px 198px #fff, 53px 1312px #fff, 1100px 119px #fff, 931px 1330px #fff,
    300px 188px #fff, 1592px 924px #fff, 691px 1831px #fff, 1378px 234px #fff,
    1536px 1130px #fff, 193px 1524px #fff, 1147px 513px #fff, 664px 600px #fff,
    804px 184px #fff, 700px 538px #fff, 1337px 650px #fff, 1716px 269px #fff,
    1711px 1096px #fff, 420px 1764px #fff, 1884px 640px #fff, 517px 1195px #fff,
    177px 1579px #fff, 790px 1880px #fff, 587px 780px #fff, 386px 1615px #fff,
    1773px 1262px #fff, 285px 1884px #fff, 1447px 1010px #fff, 385px 1111px #fff,
    1680px 290px #fff, 534px 429px #fff, 1407px 1577px #fff, 1499px 874px #fff,
    1372px 1786px #fff, 1710px 1765px #fff, 1826px 258px #fff, 538px 142px #fff,
    49px 887px #fff, 1428px 223px #fff, 672px 1873px #fff, 916px 919px #fff,
    1585px 1540px #fff, 1977px 1348px #fff, 1782px 1561px #fff,
    1735px 1749px #fff, 960px 1288px #fff, 47px 1000px #fff, 1734px 632px #fff,
    1140px 1052px #fff, 625px 783px #fff, 1042px 681px #fff, 721px 522px #fff,
    1061px 10px #fff, 1651px 1802px #fff, 660px 880px #fff, 1448px 838px #fff,
    559px 1407px #fff, 1361px 1872px #fff, 927px 1243px #fff, 966px 1762px #fff,
    1544px 982px #fff, 835px 1696px #fff, 909px 1683px #fff, 794px 1004px #fff,
    27px 1857px #fff, 1393px 144px #fff, 1592px 391px #fff, 1757px 1878px #fff,
    1024px 151px #fff, 1799px 972px #fff, 958px 1752px #fff, 1253px 818px #fff,
    315px 521px #fff, 1320px 399px #fff, 1918px 486px #fff, 1600px 1538px #fff,
    1662px 1630px #fff, 420px 708px #fff, 189px 299px #fff, 243px 100px #fff,
    1484px 1184px #fff, 1851px 1557px #fff, 611px 1421px #fff, 1892px 39px #fff,
    33px 1704px #fff, 1320px 791px #fff, 1127px 1028px #fff, 1827px 1486px #fff,
    1448px 1545px #fff, 1878px 1200px #fff, 1422px 738px #fff, 1306px 697px #fff,
    1038px 833px #fff, 1374px 666px #fff, 1192px 1804px #fff, 1945px 1843px #fff,
    1039px 687px #fff, 1565px 397px #fff, 31px 273px #fff, 529px 604px #fff,
    1994px 1838px #fff, 296px 1397px #fff, 1062px 1052px #fff,
    1321px 1358px #fff, 1262px 1407px #fff, 1859px 1736px #fff, 536px 728px #fff,
    9px 1179px #fff, 1911px 565px #fff, 246px 448px #fff, 1953px 1723px #fff,
    1947px 687px #fff, 1376px 816px #fff, 1407px 248px #fff, 576px 1748px #fff,
    269px 710px #fff, 409px 1892px #fff, 4px 1168px #fff, 1570px 115px #fff,
    1544px 580px #fff, 270px 501px #fff, 1641px 509px #fff, 1903px 931px #fff,
    740px 509px #fff, 265px 1097px #fff, 1801px 1640px #fff, 598px 1959px #fff,
    1642px 1972px #fff, 1689px 1247px #fff, 1399px 147px #fff, 667px 1947px #fff,
    96px 635px #fff, 1412px 973px #fff, 1753px 1107px #fff, 1360px 1089px #fff,
    1298px 1683px #fff, 595px 52px #fff, 1006px 372px #fff, 1492px 1574px #fff,
    112px 1692px #fff, 80px 1892px #fff, 1230px 1864px #fff, 1143px 1927px #fff,
    1237px 1136px #fff, 347px 1762px #fff, 544px 1698px #fff, 536px 1825px #fff,
    115px 774px #fff, 1581px 842px #fff, 926px 1957px #fff, 425px 809px #fff,
    923px 450px #fff, 1068px 1569px #fff, 1731px 249px #fff, 1525px 1327px #fff,
    1802px 711px #fff, 886px 1863px #fff, 1199px 1174px #fff, 721px 1522px #fff,
    793px 360px #fff, 268px 898px #fff, 368px 1907px #fff, 1678px 820px #fff,
    1856px 598px #fff, 551px 1220px #fff, 450px 647px #fff, 1469px 1879px #fff,
    1966px 784px #fff, 1320px 1688px #fff, 553px 1232px #fff, 1937px 1105px #fff,
    1658px 915px #fff, 1173px 485px #fff, 1614px 69px #fff, 835px 689px #fff,
    1429px 515px #fff, 179px 139px #fff, 1263px 1596px #fff, 733px 1800px #fff,
    1687px 515px #fff, 904px 493px #fff, 1194px 1621px #fff, 1251px 1701px #fff,
    451px 602px #fff, 1190px 7px #fff, 358px 406px #fff, 1589px 842px #fff,
    329px 858px #fff, 1683px 30px #fff, 1597px 1323px #fff, 384px 1991px #fff,
    1760px 673px #fff, 1353px 1490px #fff, 286px 226px #fff, 1735px 908px #fff,
    197px 182px #fff, 304px 793px #fff, 1430px 1295px #fff, 1615px 1410px #fff,
    209px 987px #fff, 151px 1326px #fff, 1224px 794px #fff, 1415px 1252px #fff,
    1054px 1391px #fff, 41px 263px #fff, 22px 1365px #fff, 1611px 1413px #fff,
    610px 337px #fff, 1175px 1787px #fff, 874px 39px #fff, 1160px 498px #fff,
    915px 822px #fff, 1643px 202px #fff, 1404px 1410px #fff, 753px 890px #fff,
    1664px 1673px #fff, 202px 321px #fff, 816px 1319px #fff, 1869px 515px #fff,
    1107px 834px #fff, 90px 520px #fff, 1626px 1013px #fff, 1499px 481px #fff,
    376px 1018px #fff, 204px 807px #fff, 1835px 725px #fff, 1378px 1830px #fff,
    545px 1513px #fff, 562px 913px #fff, 268px 590px #fff, 1890px 1797px #fff,
    1416px 1403px #fff, 1164px 1807px #fff, 639px 1398px #fff, 1354px 719px #fff,
    2px 193px #fff, 1402px 711px #fff, 1807px 1142px #fff, 867px 1539px #fff,
    710px 1722px #fff, 366px 748px #fff, 1621px 1688px #fff, 1585px 1741px #fff,
    1212px 771px #fff, 427px 1836px #fff, 492px 915px #fff, 1588px 114px #fff,
    1871px 85px #fff, 761px 1642px #fff, 1707px 1404px #fff, 853px 1965px #fff,
    1941px 1484px #fff, 1635px 1434px #fff, 1346px 739px #fff, 187px 366px #fff,
    1755px 1065px #fff, 273px 1568px #fff, 148px 1394px #fff, 1414px 1134px #fff,
    1027px 521px #fff, 1283px 1333px #fff, 1682px 367px #fff, 645px 441px #fff,
    1159px 1701px #fff, 482px 1269px #fff, 1602px 1205px #fff,
    1984px 1581px #fff, 1302px 1632px #fff, 406px 202px #fff, 1972px 774px #fff,
    905px 132px #fff, 1879px 1184px #fff, 1004px 19px #fff, 304px 988px #fff,
    1934px 1421px #fff, 838px 1662px #fff, 1289px 986px #fff, 306px 682px #fff,
    861px 258px #fff, 1252px 1947px #fff, 851px 682px #fff, 1330px 548px #fff,
    1934px 1521px #fff, 1055px 285px #fff, 256px 1988px #fff, 1124px 626px #fff,
    1214px 1168px #fff, 339px 1345px #fff, 1754px 1915px #fff, 422px 1778px #fff,
    1035px 291px #fff, 255px 335px #fff, 1627px 127px #fff, 1952px 765px #fff,
    1658px 827px #fff, 1209px 922px #fff, 1326px 844px #fff, 511px 1423px #fff,
    552px 1383px #fff, 812px 1276px #fff, 551px 1514px #fff, 1485px 1772px #fff,
    1409px 500px #fff, 210px 1062px #fff, 1666px 388px #fff, 899px 1591px #fff,
    1826px 736px #fff, 1328px 1106px #fff, 1284px 1595px #fff, 28px 19px #fff,
    1299px 803px #fff, 1619px 54px #fff, 1732px 1909px #fff, 1751px 216px #fff,
    1243px 1676px #fff, 580px 1566px #fff, 1460px 559px #fff, 1909px 1484px #fff,
    803px 1308px #fff, 133px 1152px #fff, 1775px 1517px #fff, 697px 917px #fff,
    346px 634px #fff, 255px 671px #fff, 1090px 639px #fff, 1998px 1418px #fff,
    481px 1319px #fff, 1638px 1471px #fff, 483px 1731px #fff, 1432px 1632px #fff,
    135px 800px #fff, 1855px 1279px #fff, 1213px 1340px #fff, 899px 1975px #fff,
    896px 1989px #fff, 1434px 1449px #fff, 969px 228px #fff, 516px 1873px #fff,
    1532px 1447px #fff, 865px 1744px #fff, 1437px 764px #fff, 2px 512px #fff,
    1849px 27px #fff, 1607px 849px #fff, 1075px 1718px #fff, 1057px 1823px #fff,
    453px 1024px #fff, 1668px 1525px #fff, 879px 1543px #fff, 1526px 72px #fff,
    1964px 1934px #fff, 1650px 892px #fff, 976px 1822px #fff, 1519px 1038px #fff,
    975px 748px #fff, 1494px 409px #fff, 12px 1380px #fff, 679px 1069px #fff,
    1467px 1355px #fff, 1999px 1033px #fff, 965px 1077px #fff,
    1449px 1815px #fff, 600px 834px #fff, 1878px 1673px #fff, 1953px 1068px #fff,
    1079px 899px #fff, 1630px 1606px #fff, 803px 1720px #fff, 746px 1213px #fff,
    1210px 226px #fff, 1800px 837px #fff, 893px 625px #fff, 1672px 845px #fff,
    1588px 543px #fff, 1027px 368px #fff, 1229px 993px #fff, 1856px 1776px #fff,
    798px 1151px #fff, 934px 1909px #fff, 1920px 747px #fff, 1513px 743px #fff,
    889px 1283px #fff, 263px 777px #fff, 785px 1578px #fff, 1438px 702px #fff,
    1854px 1022px #fff, 622px 987px #fff, 1502px 1200px #fff, 627px 896px #fff,
    1417px 830px #fff, 781px 1399px #fff, 1640px 1070px #fff, 1917px 1994px #fff,
    229px 622px #fff, 80px 821px #fff, 1914px 1805px #fff, 616px 546px #fff,
    680px 720px #fff, 1028px 1395px #fff, 681px 783px #fff, 381px 513px #fff,
    709px 1855px #fff, 1529px 1786px #fff, 1105px 1566px #fff,
    1479px 1927px #fff, 1701px 505px #fff, 543px 1840px #fff, 712px 654px #fff,
    1466px 1302px #fff, 1412px 72px #fff, 410px 1443px #fff, 1926px 689px #fff,
    1945px 147px #fff, 1586px 417px #fff, 1776px 963px #fff, 1390px 1214px #fff,
    1250px 16px #fff, 196px 44px #fff, 497px 882px #fff, 1287px 24px #fff,
    1569px 1141px #fff, 1633px 1008px #fff, 373px 890px #fff, 422px 1737px #fff,
    591px 6px #fff, 1576px 1561px #fff, 602px 546px #fff, 819px 1179px #fff,
    33px 1392px #fff, 1629px 114px #fff, 1796px 362px #fff, 232px 366px #fff,
    1763px 760px #fff, 1023px 101px #fff, 951px 1628px #fff, 921px 1940px #fff,
    907px 1966px #fff, 127px 762px #fff, 1381px 33px #fff, 1788px 574px #fff,
    1702px 889px #fff, 1701px 1283px #fff, 1854px 1906px #fff, 1391px 518px #fff,
    977px 1386px #fff, 1503px 1054px #fff, 1773px 1916px #fff, 1297px 899px #fff,
    1533px 1985px #fff, 220px 6px #fff, 1790px 1037px #fff, 1270px 315px #fff,
    1944px 1080px #fff, 403px 967px #fff, 1545px 398px #fff, 1318px 172px #fff,
    1696px 392px #fff, 1871px 1679px #fff, 549px 1321px #fff, 505px 456px #fff,
    99px 436px #fff, 257px 314px #fff, 1015px 1039px #fff, 1257px 1302px #fff,
    1137px 545px #fff, 1370px 199px #fff, 1811px 733px #fff, 1593px 658px #fff,
    685px 917px #fff, 22px 1535px #fff, 1382px 319px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1963px 1853px #fff, 774px 181px #fff, 1550px 1877px #fff,
    1853px 329px #fff, 892px 52px #fff, 449px 1910px #fff, 226px 1373px #fff,
    532px 1086px #fff, 150px 1276px #fff, 1654px 1338px #fff, 328px 1019px #fff,
    1508px 1493px #fff, 599px 800px #fff, 1213px 320px #fff, 1291px 44px #fff,
    633px 589px #fff, 1826px 692px #fff, 957px 437px #fff, 91px 495px #fff,
    15px 1958px #fff, 1765px 389px #fff, 1920px 426px #fff, 441px 1660px #fff,
    496px 1712px #fff, 141px 126px #fff, 1283px 313px #fff, 949px 1351px #fff,
    564px 20px #fff, 1908px 1078px #fff, 90px 1455px #fff, 1719px 1977px #fff,
    1934px 473px #fff, 1817px 537px #fff, 1901px 1290px #fff, 1494px 628px #fff,
    61px 1438px #fff, 1928px 1020px #fff, 1705px 1870px #fff, 713px 1184px #fff,
    1790px 615px #fff, 1441px 1099px #fff, 1179px 261px #fff, 1175px 736px #fff,
    1997px 1522px #fff, 731px 473px #fff, 1151px 1866px #fff, 1085px 1606px #fff,
    309px 1500px #fff, 1691px 356px #fff, 335px 359px #fff, 1959px 1171px #fff,
    1467px 599px #fff, 73px 181px #fff, 1728px 462px #fff, 745px 763px #fff,
    1702px 204px #fff, 1184px 1861px #fff, 1201px 912px #fff, 956px 1575px #fff,
    1915px 542px #fff, 289px 1854px #fff, 110px 1699px #fff, 719px 1056px #fff,
    124px 140px #fff, 452px 1017px #fff, 586px 1494px #fff, 1793px 74px #fff,
    877px 1069px #fff, 1004px 579px #fff, 793px 648px #fff, 1217px 1502px #fff,
    467px 1769px #fff, 1092px 846px #fff, 620px 906px #fff, 985px 1186px #fff,
    474px 226px #fff, 1603px 397px #fff, 196px 253px #fff, 519px 1289px #fff,
    157px 1107px #fff, 1551px 1234px #fff, 62px 687px #fff, 1943px 638px #fff,
    537px 1057px #fff, 969px 1393px #fff, 1299px 773px #fff, 1622px 646px #fff,
    1889px 1344px #fff, 470px 1290px #fff, 1771px 1117px #fff, 1535px 385px #fff,
    1817px 1478px #fff, 824px 362px #fff, 942px 912px #fff, 1429px 1px #fff,
    343px 331px #fff, 1560px 308px #fff, 1314px 141px #fff, 1989px 1012px #fff,
    29px 163px #fff, 1969px 482px #fff, 299px 1421px #fff, 1202px 1722px #fff,
    1509px 264px #fff, 1482px 532px #fff, 823px 1665px #fff, 1855px 876px #fff,
    1079px 1457px #fff, 1753px 913px #fff, 971px 245px #fff, 1157px 734px #fff,
    1705px 748px #fff, 31px 770px #fff, 1088px 712px #fff, 149px 58px #fff,
    1433px 240px #fff, 1589px 1086px #fff, 1871px 1016px #fff, 1133px 934px #fff,
    1743px 45px #fff, 1563px 1811px #fff, 480px 1990px #fff, 1000px 1223px #fff,
    856px 676px #fff, 649px 1049px #fff, 1596px 237px #fff, 812px 1905px #fff,
    1041px 1396px #fff, 715px 1199px #fff, 1273px 26px #fff, 978px 706px #fff,
    1468px 1766px #fff, 1431px 79px #fff, 645px 1394px #fff, 616px 203px #fff,
    1216px 1944px #fff, 1171px 344px #fff, 262px 1408px #fff, 221px 656px #fff,
    1627px 545px #fff, 9px 1535px #fff, 1471px 661px #fff, 47px 16px #fff,
    1164px 1655px #fff, 716px 878px #fff, 1420px 1712px #fff, 68px 620px #fff,
    656px 908px #fff, 1345px 1009px #fff, 675px 660px #fff, 1639px 1211px #fff,
    1425px 1500px #fff, 1093px 1451px #fff, 1640px 312px #fff, 355px 1375px #fff,
    819px 444px #fff, 505px 1020px #fff, 1924px 1192px #fff, 1158px 616px #fff,
    38px 50px #fff, 1734px 1582px #fff, 1826px 1879px #fff, 1422px 380px #fff,
    1859px 653px #fff, 1511px 1234px #fff, 1964px 1197px #fff, 833px 535px #fff,
    1243px 1112px #fff, 946px 635px #fff, 474px 1195px #fff, 1525px 190px #fff,
    1953px 1445px #fff, 1800px 787px #fff, 1621px 1321px #fff, 894px 1302px #fff,
    1189px 821px #fff, 501px 471px #fff, 1527px 1094px #fff, 481px 1513px #fff,
    552px 928px #fff, 1800px 1788px #fff, 264px 1132px #fff, 1382px 886px #fff,
    148px 1021px #fff, 1639px 233px #fff, 395px 232px #fff, 1191px 1427px #fff,
    474px 1231px #fff, 1845px 1327px #fff, 1359px 272px #fff, 1823px 1551px #fff,
    1844px 979px #fff, 448px 245px #fff, 521px 956px #fff, 1253px 8px #fff,
    1267px 837px #fff, 1865px 1177px #fff, 1374px 947px #fff, 472px 223px #fff,
    1764px 1254px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1963px 1853px #fff, 774px 181px #fff, 1550px 1877px #fff,
    1853px 329px #fff, 892px 52px #fff, 449px 1910px #fff, 226px 1373px #fff,
    532px 1086px #fff, 150px 1276px #fff, 1654px 1338px #fff, 328px 1019px #fff,
    1508px 1493px #fff, 599px 800px #fff, 1213px 320px #fff, 1291px 44px #fff,
    633px 589px #fff, 1826px 692px #fff, 957px 437px #fff, 91px 495px #fff,
    15px 1958px #fff, 1765px 389px #fff, 1920px 426px #fff, 441px 1660px #fff,
    496px 1712px #fff, 141px 126px #fff, 1283px 313px #fff, 949px 1351px #fff,
    564px 20px #fff, 1908px 1078px #fff, 90px 1455px #fff, 1719px 1977px #fff,
    1934px 473px #fff, 1817px 537px #fff, 1901px 1290px #fff, 1494px 628px #fff,
    61px 1438px #fff, 1928px 1020px #fff, 1705px 1870px #fff, 713px 1184px #fff,
    1790px 615px #fff, 1441px 1099px #fff, 1179px 261px #fff, 1175px 736px #fff,
    1997px 1522px #fff, 731px 473px #fff, 1151px 1866px #fff, 1085px 1606px #fff,
    309px 1500px #fff, 1691px 356px #fff, 335px 359px #fff, 1959px 1171px #fff,
    1467px 599px #fff, 73px 181px #fff, 1728px 462px #fff, 745px 763px #fff,
    1702px 204px #fff, 1184px 1861px #fff, 1201px 912px #fff, 956px 1575px #fff,
    1915px 542px #fff, 289px 1854px #fff, 110px 1699px #fff, 719px 1056px #fff,
    124px 140px #fff, 452px 1017px #fff, 586px 1494px #fff, 1793px 74px #fff,
    877px 1069px #fff, 1004px 579px #fff, 793px 648px #fff, 1217px 1502px #fff,
    467px 1769px #fff, 1092px 846px #fff, 620px 906px #fff, 985px 1186px #fff,
    474px 226px #fff, 1603px 397px #fff, 196px 253px #fff, 519px 1289px #fff,
    157px 1107px #fff, 1551px 1234px #fff, 62px 687px #fff, 1943px 638px #fff,
    537px 1057px #fff, 969px 1393px #fff, 1299px 773px #fff, 1622px 646px #fff,
    1889px 1344px #fff, 470px 1290px #fff, 1771px 1117px #fff, 1535px 385px #fff,
    1817px 1478px #fff, 824px 362px #fff, 942px 912px #fff, 1429px 1px #fff,
    343px 331px #fff, 1560px 308px #fff, 1314px 141px #fff, 1989px 1012px #fff,
    29px 163px #fff, 1969px 482px #fff, 299px 1421px #fff, 1202px 1722px #fff,
    1509px 264px #fff, 1482px 532px #fff, 823px 1665px #fff, 1855px 876px #fff,
    1079px 1457px #fff, 1753px 913px #fff, 971px 245px #fff, 1157px 734px #fff,
    1705px 748px #fff, 31px 770px #fff, 1088px 712px #fff, 149px 58px #fff,
    1433px 240px #fff, 1589px 1086px #fff, 1871px 1016px #fff, 1133px 934px #fff,
    1743px 45px #fff, 1563px 1811px #fff, 480px 1990px #fff, 1000px 1223px #fff,
    856px 676px #fff, 649px 1049px #fff, 1596px 237px #fff, 812px 1905px #fff,
    1041px 1396px #fff, 715px 1199px #fff, 1273px 26px #fff, 978px 706px #fff,
    1468px 1766px #fff, 1431px 79px #fff, 645px 1394px #fff, 616px 203px #fff,
    1216px 1944px #fff, 1171px 344px #fff, 262px 1408px #fff, 221px 656px #fff,
    1627px 545px #fff, 9px 1535px #fff, 1471px 661px #fff, 47px 16px #fff,
    1164px 1655px #fff, 716px 878px #fff, 1420px 1712px #fff, 68px 620px #fff,
    656px 908px #fff, 1345px 1009px #fff, 675px 660px #fff, 1639px 1211px #fff,
    1425px 1500px #fff, 1093px 1451px #fff, 1640px 312px #fff, 355px 1375px #fff,
    819px 444px #fff, 505px 1020px #fff, 1924px 1192px #fff, 1158px 616px #fff,
    38px 50px #fff, 1734px 1582px #fff, 1826px 1879px #fff, 1422px 380px #fff,
    1859px 653px #fff, 1511px 1234px #fff, 1964px 1197px #fff, 833px 535px #fff,
    1243px 1112px #fff, 946px 635px #fff, 474px 1195px #fff, 1525px 190px #fff,
    1953px 1445px #fff, 1800px 787px #fff, 1621px 1321px #fff, 894px 1302px #fff,
    1189px 821px #fff, 501px 471px #fff, 1527px 1094px #fff, 481px 1513px #fff,
    552px 928px #fff, 1800px 1788px #fff, 264px 1132px #fff, 1382px 886px #fff,
    148px 1021px #fff, 1639px 233px #fff, 395px 232px #fff, 1191px 1427px #fff,
    474px 1231px #fff, 1845px 1327px #fff, 1359px 272px #fff, 1823px 1551px #fff,
    1844px 979px #fff, 448px 245px #fff, 521px 956px #fff, 1253px 8px #fff,
    1267px 837px #fff, 1865px 1177px #fff, 1374px 947px #fff, 472px 223px #fff,
    1764px 1254px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1101px 1725px #fff, 1569px 567px #fff, 1790px 1578px #fff,
    1469px 970px #fff, 90px 1125px #fff, 1675px 1921px #fff, 1334px 686px #fff,
    928px 1239px #fff, 1829px 351px #fff, 75px 1709px #fff, 636px 478px #fff,
    1814px 407px #fff, 609px 1189px #fff, 192px 1077px #fff, 721px 1829px #fff,
    1660px 1146px #fff, 1054px 881px #fff, 557px 535px #fff, 1129px 664px #fff,
    47px 371px #fff, 510px 1322px #fff, 1037px 181px #fff, 872px 1838px #fff,
    1503px 536px #fff, 249px 661px #fff, 1783px 1385px #fff, 1003px 1365px #fff,
    1319px 702px #fff, 626px 1418px #fff, 1770px 666px #fff, 963px 433px #fff,
    843px 1322px #fff, 470px 50px #fff, 1627px 137px #fff, 1500px 107px #fff,
    1232px 1516px #fff, 1693px 385px #fff, 654px 1549px #fff, 1895px 508px #fff,
    1468px 177px #fff, 534px 613px #fff, 1193px 56px #fff, 1155px 1666px #fff,
    485px 1370px #fff, 617px 344px #fff, 1573px 544px #fff, 1704px 767px #fff,
    1016px 843px #fff, 1344px 468px #fff, 840px 116px #fff, 418px 13px #fff,
    815px 179px #fff, 759px 900px #fff, 1018px 659px #fff, 1801px 281px #fff,
    998px 1351px #fff, 1614px 653px #fff, 1033px 264px #fff, 164px 1601px #fff,
    1983px 1461px #fff, 1453px 1440px #fff, 1655px 1480px #fff,
    900px 1596px #fff, 686px 1099px #fff, 1673px 519px #fff, 919px 360px #fff,
    1535px 1717px #fff, 603px 1127px #fff, 74px 938px #fff, 1249px 993px #fff,
    1726px 702px #fff, 1468px 1591px #fff, 201px 104px #fff, 509px 842px #fff,
    1355px 384px #fff, 356px 101px #fff, 1554px 1418px #fff, 1435px 634px #fff,
    1448px 1453px #fff, 675px 1355px #fff, 1712px 1365px #fff,
    1641px 1917px #fff, 184px 1751px #fff, 1961px 1441px #fff, 1595px 786px #fff,
    1830px 1492px #fff, 949px 1582px #fff, 1557px 38px #fff, 344px 899px #fff,
    783px 1928px #fff, 1152px 558px #fff, 722px 428px #fff, 779px 1571px #fff,
    175px 1311px #fff, 821px 1671px #fff, 1390px 1233px #fff, 420px 699px #fff,
    1276px 397px #fff, 1523px 1438px #fff, 1579px 389px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1101px 1725px #fff, 1569px 567px #fff, 1790px 1578px #fff,
    1469px 970px #fff, 90px 1125px #fff, 1675px 1921px #fff, 1334px 686px #fff,
    928px 1239px #fff, 1829px 351px #fff, 75px 1709px #fff, 636px 478px #fff,
    1814px 407px #fff, 609px 1189px #fff, 192px 1077px #fff, 721px 1829px #fff,
    1660px 1146px #fff, 1054px 881px #fff, 557px 535px #fff, 1129px 664px #fff,
    47px 371px #fff, 510px 1322px #fff, 1037px 181px #fff, 872px 1838px #fff,
    1503px 536px #fff, 249px 661px #fff, 1783px 1385px #fff, 1003px 1365px #fff,
    1319px 702px #fff, 626px 1418px #fff, 1770px 666px #fff, 963px 433px #fff,
    843px 1322px #fff, 470px 50px #fff, 1627px 137px #fff, 1500px 107px #fff,
    1232px 1516px #fff, 1693px 385px #fff, 654px 1549px #fff, 1895px 508px #fff,
    1468px 177px #fff, 534px 613px #fff, 1193px 56px #fff, 1155px 1666px #fff,
    485px 1370px #fff, 617px 344px #fff, 1573px 544px #fff, 1704px 767px #fff,
    1016px 843px #fff, 1344px 468px #fff, 840px 116px #fff, 418px 13px #fff,
    815px 179px #fff, 759px 900px #fff, 1018px 659px #fff, 1801px 281px #fff,
    998px 1351px #fff, 1614px 653px #fff, 1033px 264px #fff, 164px 1601px #fff,
    1983px 1461px #fff, 1453px 1440px #fff, 1655px 1480px #fff,
    900px 1596px #fff, 686px 1099px #fff, 1673px 519px #fff, 919px 360px #fff,
    1535px 1717px #fff, 603px 1127px #fff, 74px 938px #fff, 1249px 993px #fff,
    1726px 702px #fff, 1468px 1591px #fff, 201px 104px #fff, 509px 842px #fff,
    1355px 384px #fff, 356px 101px #fff, 1554px 1418px #fff, 1435px 634px #fff,
    1448px 1453px #fff, 675px 1355px #fff, 1712px 1365px #fff,
    1641px 1917px #fff, 184px 1751px #fff, 1961px 1441px #fff, 1595px 786px #fff,
    1830px 1492px #fff, 949px 1582px #fff, 1557px 38px #fff, 344px 899px #fff,
    783px 1928px #fff, 1152px 558px #fff, 722px 428px #fff, 779px 1571px #fff,
    175px 1311px #fff, 821px 1671px #fff, 1390px 1233px #fff, 420px 699px #fff,
    1276px 397px #fff, 1523px 1438px #fff, 1579px 389px #fff;
}

#title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-family: "lato", sans-serif;
  font-weight: 300;
  font-size: 50px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
}
#title span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
