@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

 



.speakers {
  width: 100%;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -100;
  padding-top: 3%;

  /* background: linear-gradient(45deg ,  rgba(0,0,0) ,rgba(55,15,17), rgba(0,0,0)); */
/*   
  animation-name: animate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-fill-mode: forwards; */

}



@keyframes animate{
  0%{
    background: linear-gradient(45deg ,  rgba(0,0,0) ,rgba(55,15,17), rgba(0,0,0));
  }
  50%{
    background: linear-gradient(200deg ,  rgba(0,0,0) ,rgba(55,15,17), rgba(0,0,0));
  }
  100%{
    background: linear-gradient(135deg ,  rgba(0,0,0) ,rgba(55,15,17), rgba(0,0,0));
  }
}



.heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-block: 2rem;
  
}

.title {
  background: -webkit-linear-gradient(45deg, rgba(195,146,52) ,rgb(169, 160, 97), rgba(195,146,52));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3rem;
  transition: .4s ease;
  padding-top: 5%;
  padding-bottom: 0%;
  
}



.title:hover{
  
  letter-spacing: 1rem;
  transform: translateX(7px);
  filter: brightness(140%);
  /* text-decoration: underline;
  text-decoration-color: rgb(169, 160, 97);
  text-decoration-thickness: 2px;
  text-underline-offset: 10px; */
    
}


  


.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 3% 7% 0% 7%;
}

.speakerCard {
  display: flex;
  margin-bottom: 9rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.speakerImg {
  

  max-width: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 28rem;
  flex-direction: column;
  position: relative;
  filter: brightness(105%)
  
}

/* .speakerImg:hover{
  filter: brightness(105%);
} */


.image:hover{
 
  filter: drop-shadow(0px 0px 10px rgba(195,146,52,0.7));
  
}



.image {
  height: 22rem;
  width: auto;
  border-image: linear-gradient(45deg, rgba(239,228,155,0) ,rgba(239,228,155), rgba(195,146,52)) 1;
  border-width: 0.2rem;
  border-style: solid;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 0px 10px rgba(195,146,52,0.4));
  
  animation-name: bordercircle;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-delay: 0s;
	animation-direction:normal;


  transition: .3s ease;

  backdrop-filter: blur(10px);

}



@keyframes bordercircle {
  0%{
    border-image: linear-gradient(45deg, rgba(239,228,155,0) ,rgba(239,228,155), rgba(195,146,52)) 1;
  }
  25%{
    border-image: linear-gradient(225deg, rgba(239,228,155,0) ,rgba(239,228,155), rgba(195,146,52)) 1;
  }
  50%{
    border-image: linear-gradient(-45deg, rgba(239,228,155,0) ,rgba(239,228,155), rgba(195,146,52)) 1;
  }
  75%{
    border-image: linear-gradient(135deg, rgba(239,228,155,0) ,rgba(239,228,155), rgba(195,146,52)) 1;
  }
 
  100%{
    border-image: linear-gradient(45deg, rgba(239,228,155,0) ,rgba(239,228,155), rgba(195,146,52)) 1;
  }
  
}


.speakerData {
  display: flex;
  flex-direction: column;
  align-self: center;
  height: auto;
  padding-inline: 4rem;
  max-width: 60rem;
  /* background-image: url(../images/frames/maskgroup.png);
  background-size:cover;
  background-position: right;
  background-repeat: no-repeat; */
  border-right: 0px solid rgb(169, 160, 97);
transition: .2s ease-in-out;
/* transition-property: filter; */

}

.speakerData:hover{
  filter:brightness(130%);
  border-right: 2px solid rgb(169, 160, 97);
  background: linear-gradient(90deg,rgba(239,228,155,0) ,rgba(239,228,155,0), rgba(195,146,52,0.1));
}



.name {
  color: #D1AA52;
  font-family: Zen Dots;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08rem;
  text-transform: capitalize;
 
}


.displayname{
  width: 22rem;
  height: 4.5rem;
  background: rgba(195,146,52,0.3);
  position: absolute;
  bottom: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
 display: none;

  
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-delay: 0s;
	animation-direction:normal;

}

.displayname span:nth-child(1){
  color: white;
  font-family: Zen Dots;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  
}
.displayname span:nth-child(2){
  color: white;
  font-family: Zen Dots;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.08rem;
  text-transform: lowercase;
  
}
.image:hover + .displayname{
  display: flex;
  animation-name: appear;
  
}

.displayname:hover{
  display: flex;
  animation-name: appear;
}



@keyframes appear{
  0%{
    height: 0;
    
  }
  60%{
    height: 5rem;
    
  }
  80%{
    height: 4.2rem;
    
  }
  100%{
    height: 4.5rem;
    
  }
}









.company {
  color: #D9C987;
  font-family: Zen Dots;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.16rem;
  text-transform: capitalize;
}

.info {
  color: #fff;
  font-family: Zen Dots;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.12rem;
  padding-top: 1rem;
  text-align: justify;
  padding-right: 5vw;
  background-image: url(../images/frames/maskgroup.png);
  background-size:cover;
  background-position: right;
  background-repeat: no-repeat;
}



 
@media only screen and (max-width: 992px) {
  .speakerImg{
    margin-bottom: 10%;
  }
  .info{
    font-size: 2vw;
  }

 
} 


/* FOR MOBILE, TABLET */
@media only screen and (max-width: 550px) {
  .speakerCard {
    max-width: 48rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;
  }
  .speakerData{
    justify-content: center;
    margin-inline: 1.5rem;
    padding-inline: 1rem;
  }
  .speakerImg{
    max-height: 20rem;
    margin-bottom: 10%;
    padding: 2%;
  }
  .image{
    width: 80%;
    height: 20rem;
  }
  .name, .company{
    text-align: center;
  }
  .dir{
    flex-direction: column;
  }
  .speakers{
    padding-top: 10%;
  }
  .displayname{
   max-width: 74% ;
   bottom: 10px;
  }
  .info{
    font-size: 0.8rem;
  }
}


/* FOR LAPTOP, DESKTOP */

@media only screen and (min-width: 992px) {
  .title {
    font-size: 5rem;
  }

  .speakerCard > * {
    flex: 1 1;
  }



  .dir{
    flex-direction: row;
  }
  .altdir{
    flex-direction: row-reverse;
  }
  .speakerCard {
    justify-content: space-evenly;
  }
  .speakerData{
    padding-right: 0rem;
  }

}

@media (max-width:400px){
  .title{
    font-size: 3.5rem;
  }

  .name{
    font-size: 2.5rem;
  }
  .displayname{
    bottom: 8px;
  }
}