.galleryContainer {
  margin: 15% 30%;
  display: flex;
 
 /* Set the background color to black */
}
.heading  {
  font-size: 4em;
  color: white;
  font-weight: bold; /* Optional: make the text bold */
  margin: 0; /* Adjust margin as needed */
}


  .photoFrameWrapper {
    position: relative;
    margin-right: -180px; /* Adjust the overlap amount as needed */
  }
  
  .photoFrameWrapper:last-child {
    margin-right: 0; /* Remove margin from the last photo frame */
  }
/* Grid layout styles */
.gridLayout .galleryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.gridLayout .photoFrameWrapper {
  width: calc(80% - 1%);
  margin-bottom: 8%;
  margin-top: 8%;
  margin-left: 10%;
  
}

.gridLayout {
  display: grid;
  grid-gap: 0%;
  grid-template-columns: repeat(3, 1fr);
  
  /* background: -webkit-linear-gradient(128deg, #000000 0%, #06063d 50%, #781aaf 100%);
  background: linear-gradient(128deg, #000000 0%, #06063d 50%, #781aaf 100%); */




 
  
  
  @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
  @media (max-width: 414px) {
        grid-template-columns: repeat(1, 1fr);
  }
}
}
.heading {
  text-align: center;
  background: -webkit-linear-gradient(45deg, rgba(195,146,52) ,rgb(169, 160, 97), rgba(195,146,52));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* Add the standard property 'background-clip' */
  font-family: Poppins;
  padding-top:80px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.3rem;
  transition: .4s ease;
}
.heading:hover{
  letter-spacing: 0.8rem;
  transform: translateX(7px);
  filter: brightness(140%);
}

.heading h1 {
  font-size: 48px;
  font-weight: bold;
  color:white; /* Adjust the color as needed */
  margin: 0;
}
.heading h2 {
  font-size: 32px;
  
  color: rgba(189, 155, 253, 1);
  /* Adjust the color as needed */
  margin: 0;
}

