@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Princess+Sofia&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

.mouseCircle {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99999999;
}

* {
  cursor: none;
}
.particlesbackground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -150; /* Lower z-index to make it appear behind other elements */
  top: 0;
  left: 0;
}

/* Webkit scrollbar styles for better cross-browser support */
::-webkit-scrollbar {
  width: 2mm; /* Set the width of the scrollbar */
  height: 100mm; /* Set the height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: rgb(0, 0, 0); /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #89828271; /* Color of the scrollbar thumb */
  height: 2mm;
  width: 2mm;
  border-radius: 10px; /* Round the corners of the scrollbar thumb */
}

.cloud1 {
  animation: cloud1;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes cloud1 {
  0% {
    transform: translateY(7px);
  }
  100% {
    transform: translateY(-7px);
  }
}
.cloud2 {
  animation: cloud2;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes cloud2 {
  0% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(-7px);
  }
}
.cloud3 {
  animation: cloud3;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes cloud3 {
  0% {
    transform: translateY(-7px) translateX(2px);
  }
  100% {
    transform: translateY(7px) translateX(-2px);
  }
}

.princess-sofia-regular {
  font-family: "Princess Sofia", cursive;
  font-weight: 900;
  font-style: normal;
}
.orbitron {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.motion {
  animation: move;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes move {
  0% {
    transform: translateY(-6px);
    letter-spacing: 1rem;
  }
  100% {
    transform: translateY(+6px);
    letter-spacing: 2rem;
  }
}

.dancingscript {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-style: normal;
}
