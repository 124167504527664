.contain {
  margin: 0;
  width: 100vw;

  background: #0c1447;
  background: linear-gradient(-45deg, #4c3b69 0%, #0c1447 50%, #4c3b69 100%);
  /* height: 100vh; */
}
.con1 {
  padding-top: 60px;
  padding-bottom: 100px;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.con2 {
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
}
.box {
  height: 200px;
  width: 200px;
  background-color: rgba(75, 49, 103, 0.347);
  /* border-radius: 100%; */
  margin: 30px;
  margin-bottom: 25px;
  padding: 15px;
  margin-left: 30px;
  margin-right: 30px;
}
.item_title {
  color: white;
  font-size: x-large;
  font-weight: 500;
  font-family: Nunito Sans;
  text-align: center;
  padding: 5px;
  padding-left: 22px;
  padding-right: 22px;
}
.animate {
  animation-name: backgroundColorPalette;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes backgroundColorPalette {
  0% {
    background: #351f51b9;
    box-shadow: 0px 0px 40px 30px #351f51e5, 25px 0px 20px -20px #351f51e5,
      0px 25px 20px -20px #351f51e5, -25px 0px 20px -20px #351f51e5,
      0px 15px 12px 0px #351f51e5, 0px 15px 12px 0px #351f51e5;
  }
  25% {
    background: #6968bee0;
    box-shadow: 0px 0px 40px 30px #6968bee0, 25px 0px 20px -20px #6968bee0,
      0px 25px 20px -20px #6968bee0, -25px 0px 20px -20px #6968bee0,
      0px 15px 12px 0px #6968bee0, 0px 15px 12px 0px #6968bee0;
  }
  50% {
    background: #0c144761;
    box-shadow: 0px 0px 40px 30px #0c144761, 25px 0px 20px -20px #0c144761,
      0px 25px 20px -20px #0c144761, -25px 0px 20px -20px #0c144761,
      0px 15px 12px 0px #0c144761, 0px 15px 12px 0px #0c144761;
  }
  75% {
    background: #171f755f;
    box-shadow: 0px 0px 40px 30px #171f755f, 25px 0px 20px -20px #171f755f,
      0px 25px 20px -20px #171f755f, -25px 0px 20px -20px #171f755f,
      0px 15px 12px 0px #171f755f, 0px 15px 12px 0px #171f755f;
  }
  100% {
    background: #b798ac9b;
    box-shadow: 0px 0px 40px 30px #b798ac8f, 25px 0px 20px -20px #b798ac8f,
      0px 25px 20px -20px #b798ac8f, -25px 0px 20px -20px #b798ac8f,
      0px 15px 12px 0px #b798ac8f, 0px 15px 12px 0px #b798ac8f;
  }
}
.item {
  height: 170px;
  width: 170px;
  /* margin: 15px; */
  background-color: #0a0917;
  border-radius: 100%;
  text-align: center;
  padding-top: 70px;
}
@media (max-width: 830px) {
  .con1 {
    top: 100px;
  }
  .box {
    height: 150px;
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }
  .item {
    height: 130px;
    width: 130px;

    text-align: center;
    padding-top: 50px;
  }
  .item_title {
    font-size: larger;
    font-family: Nunito Sans;
  }
}
@media (max-width: 650px) {
  .box {
    height: 120px;
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
  }
  .item {
    height: 110px;
    width: 110px;
    /* margin:5px; */
    text-align: center;
    padding-top: 40px;
  }
  .item_title {
    font-size: larger;
    font-family: Nunito Sans;
  }
}
.count {
  color: white;
  font-size: x-large;
  font-family: Nunito Sans;
}
.box {
  margin: 0 auto;
  margin-bottom: 10px;
}
@media (width: 768px) {
  .box {
    margin-left: 60px;
    margin-right: 60px;
  }
}
@media (max-width: 400px) {
  .con1 {
    display: block;
  }
}
